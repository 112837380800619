/* eslint-disable no-param-reassign */
import {
    SET_PROSPECT_INTERACTION_STATE,
    SET_STARS,
    TOGGLE_FLAG
} from '../actions/types';
import {setUserState} from '../util/lib';

const initialState = {
    starred: [],
    flagged: []
};

export default function(state = initialState, action) {
    switch (action.type) {
    case SET_STARS: {
        state = {
            ...state,
            starred: [...state.starred.filter(s => s.org_id !== action.payload.org_id), {org_id: action.payload.org_id, stars: action.payload.stars} ]
        };
        break;
    }

    case TOGGLE_FLAG: {
        state = {
            ...state,
            flagged: state.flagged.includes(action.payload.org_id) ? state.flagged.filter(i => i !== action.payload.org_id) : [...state.flagged, action.payload.org_id]
        };
        break;
    }

    case SET_PROSPECT_INTERACTION_STATE: {
        state = action.payload.userState;

        return state;
    }

    default:
        return state;
    }

    setUserState({prospectInteraction: state});

    return state;
}

