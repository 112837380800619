/* eslint-disable func-style, no-console */
import callApi from './callApi';

export const toTitleCase = str => str.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
);


export const setUserState = async data => {
    if (data) {
        try {
            await callApi('db/userState', 'set', {state: data, timestamp: +Date.now()});
            console.log('Set User State');

            return true;
        } catch (e) {
            console.error('Failed to set user state');
        }
    }

    return null;
};

export const makeNumberPretty = (num, precision) => parseInt(num).toLocaleString(
    undefined,
    {minimumFractionDigits: precision}
);


export const decodeQueryString = queryString => {
    let qs = queryString || window.location.search;

    const queryStrings = {};
    // ? should be decoded
    if (qs.indexOf('?') > -1) {
        qs = qs.replace('?', '');
    }

    qs.split('&').forEach(pair => {
        const res = pair.split('=');

        queryStrings[(res[0] || '').toString()] = decodeURIComponent(res[1] || '');
    });

    return queryStrings;
};