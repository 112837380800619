/* eslint-disable no-console */
import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {Authenticator} from 'aws-amplify-react';
import {Container} from 'semantic-ui-react';
import {default as ConfirmSignIn} from './scenes/Authentication/CustomConfirmSignIn';
import {default as ConfirmSignUp} from './scenes/Authentication/CustomConfirmSignUp';
import {default as ForgotPassword} from './scenes/Authentication/CustomForgotPassword';
import {default as SignIn} from './scenes/Authentication/CustomSignIn';
import AmplifyTheme from './scenes/Authentication/components/AmplifyTheme';
import {default as VerifyContact} from './scenes/Authentication/CustomVerifyContact';
import {default as RequireNewPassword} from './scenes/Authentication/CustomRequireNewPassword';
import './App.css';
import PropTypes from 'prop-types';

import ProspectList from './scenes/ProspectList';
import ProspectProfile from './scenes/ProspectProfile';
import NotFound from './scenes/NotFound';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faMinusCircle, faPlusCircle} from '@fortawesome/free-solid-svg-icons';


import {connect} from 'react-redux';
import Header from './scenes/Header';
import Tools from './scenes/Tools';

import Settings from './scenes/Settings';
import callApi from './util/callApi';
import {setProspectListState, fetchUser, setProspectInteractionState} from './actions';

library.add(faMinusCircle, faPlusCircle);

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userStateLoaded: false
        };
    }

    // Get state from server and set on redux, if available
    async getUserState() {
        this.props.fetchUser(false);
        try {
            const userState = await callApi('db/userState', 'read', {});
            console.log('USER STATE ', userState);
            if (userState.prospectList) {
                this.props.setProspectListState(userState.prospectList);
            }

            if (userState.prospectInteraction) {
                this.props.setProspectInteractionState(userState.prospectInteraction);
            }
            // this.setState({userStateLoaded: true})
        } catch (e) {
            // this.setState({userStateLoaded: true})
        } finally {
            this.setState({userStateLoaded: true}, () => console.log(this.state));
        }

        return;
    }

    async componentDidMount() {
        // Check user on mount
        await this.getUserState();
    }

    async componentDidUpdate(prevProps /* , prevState, snapshot */) {
        // Check user on login
        console.log(this.props, prevProps);
        if (prevProps.auth === false && this.props.auth !== false) {
            await this.getUserState();
        }
    }

    render() {
        return (
            <div className='App'
            // eslint-disable-next-line no-negated-condition
                style={!this.props.auth ?
                    {
                        backgroundColor: 'rgb(247, 247, 247)',
                        height: '100%'
                    } :
                    null}
            >
                <Helmet>
                    <title>Mozaic</title>
                </Helmet>
                <BrowserRouter>
                    <div>
                        <Header/>
                        <Authenticator
                            theme={AmplifyTheme}
                            hideDefault={true}
                        >
                            {/* <Form size='large'>*/}
                            {/*    <Segment stacked>*/}
                            {/*        <Form.Input fluid icon='user' iconPosition='left' placeholder='E-mail address' />*/}
                            {/*        <Form.Input*/}
                            {/*            fluid*/}
                            {/*            icon='lock'*/}
                            {/*            iconPosition='left'*/}
                            {/*            placeholder='Password'*/}
                            {/*            type='password'*/}
                            {/*        />*/}

                            {/*        <Button color='blue' fluid size='large'>*/}
                            {/*            Login*/}
                            {/*        </Button>*/}
                            {/*    </Segment>*/}
                            {/* </Form>*/}
                            <SignIn/>
                            <ConfirmSignIn/>
                            <VerifyContact/>
                            <ConfirmSignUp/>
                            <ForgotPassword/>
                            <RequireNewPassword/>
                            {this.props && this.props.auth ?
                                <Container fluid style={{padding: '0 1em'}}>
                                    <Switch>
                                        {/* NOTE: greedy paths by default apparently. */}
                                        {/* <Route exact path={'/'} component={Dashboard}/>*/}
                                        <Route exact path={'/'}
                                            component={props => <ProspectList {...props}
                                                userStateLoaded={this.state.userStateLoaded || false}/>}/>
                                        <Route path={'/prospect/profile/'} component={ProspectProfile}/>
                                        <Route path={'/prospect/'}
                                            component={props => <ProspectList {...props}
                                                userStateLoaded={this.state.userStateLoaded || false}/>}/>
                                        <Route path={'/tools/'} component={Tools}/>
                                        <Route path={'/account/details'} component={Settings}/>
                                        {/* <Route path={'/account/user-management'} component={Landing}/>*/}
                                        {/* <Route path={'/account/create-user'} component={CreateUser}/>*/}
                                        {/* <Route path={'/account/create-group'} component={CreateGroup}/>*/}
                                        {/* <Route path={'/salesforce/callback/'} component={SalesforceCallbackEndpoint}/>*/}

                                        {/* IMPORTANT!  Make sure this is last. */}
                                        <Route path={'/'} status={404} component={NotFound}/>
                                    </Switch>
                                </Container> :
                                <Container>
                                </Container>
                            }
                        </Authenticator>
                    </div>
                </BrowserRouter>

            </div>
        );
    }
}

App.propTypes = {
    fetchUser: PropTypes.func,
    setProspectListState: PropTypes.func,
    setProspectInteractionState: PropTypes.func,
    auth: PropTypes.bool
};

// eslint-disable-next-line func-style
function mapStateToProps({auth}) {
    return {auth};
}

const mapDispatchToProps = {
    setProspectListState,
    setProspectInteractionState,
    fetchUser
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
