const fieldList = {
    // 'name': {'name': 'Name', 'key': 'name', 'type': 'string'},
    'primary_city': {'name': 'City', 'key': 'primary_city', 'type': 'string'},
    'primary_state': {'name': 'State', 'key': 'primary_state', 'type': 'string'},
    'primary_pwsid': {'name': 'Water System ID', 'key': 'primary_pwsid', 'type': 'string'},
    'main_url': {'name': 'Site Url', 'key': 'main_url', 'type': 'string'},
    'zip_code': {'name': 'Postal Code', 'key': 'zip_code', 'type': 'string'},
    'phone_number': {'name': 'Phone Number', 'key': 'phone_number', 'type': 'string'},
    'population_served_count': {'name': 'Population Served', 'key': 'population_served_count', 'type': 'number'},
    'service_connections_count': {'name': 'Service Connections', 'key': 'service_connections_count', 'type': 'number'},
    'epa_region': {'name': 'EPA Region', 'key': 'epa_region', 'type': 'string'},
    'gw_sw_code': {'name': 'Water Type', 'key': 'gw_sw_code', 'type': 'string'},
    'is_school_or_daycare_ind': {'name': 'Supplies Schools?', 'key': 'is_school_or_daycare_ind', 'type': 'bool'},
    'cities_served': {'name': 'Cities Served', 'key': 'cities_served', 'type': 'string'},
    'counties_served': {'name': 'Counties Served', 'key': 'counties_served', 'type': 'string'},
    'sdwis_violation_count': {'name': 'EPA Violation Count', 'key': 'sdwis_violation_count', 'type': 'number'},
    'epa_lcr_sample_count': {'name': 'EPA LCR Sample Count', 'key': 'epa_lcr_sample_count', 'type': 'number'},
    'max_lead': {name: 'Maximum Sampled Lead', key: 'max_lead', type: 'number'},
    'max_copper': {name: 'Maximum Sampled Copper', key: 'max_copper', type: 'number'},
    'over_limit_lead_count': {name: 'Lead Samples over LCR Limit', key: 'over_limit_lead_count', type: 'number'},
    'over_limit_copper_count': {name: 'Copper Samples over LCR Limit', key: 'over_limit_copper_count', type: 'number'},
    'water_utilities_revenue': {'name': 'County Water Utility Revenue', 'key': 'water_utilities_revenue', 'type': 'number'},
    'water_utilities_current_oper': {'name': 'County Water Utility Operating Expenses', 'key': 'water_utilities_current_oper', 'type': 'number'},
    'water_utilities_construction': {'name': 'County Water Utility Construction', 'key': 'water_utilities_construction', 'type': 'number'},
    'water_utilities_other_cap_out': {'name': 'County Water Utility Capital Outlay', 'key': 'water_utilities_other_cap_out', 'type': 'number'},
    'water_utilities_interest': {'name': 'County Water Utility Interest', 'key': 'water_utilities_interest', 'type': 'number'},
    'water_utilities_ig_to_state': {'name': 'County Water Utility Intergovernmental Revenue (State)', 'key': 'water_utilities_ig_to_state', 'type': 'number'},
    'water_utilities_ig_to_local': {'name': 'County Water Utility Intergovernmental Revenue (Local)', 'key': 'water_utilities_ig_to_local', 'type': 'number'},
    'mentions_cipp': {'name': 'Mentions CIPP', 'key': 'mentions_cipp', 'type': 'number'},
    'mentions_trenchless': {'name': 'Mentions Trenchless', 'key': 'mentions_trenchless', 'type': 'number'},
    'mentions_water_main_lining': {'name': 'Mentions Water Main Lining', 'key': 'mentions_water_main_lining', 'type': 'number'},
    'mentions_lead_service_line': {'name': 'Mentions Lead Service Line', 'key': 'mentions_lead_service_line', 'type': 'number'},
    'mentions_dig_and_replace': {'name': 'Mentions Dig & Replace', 'key': 'mentions_dig_and_replace', 'type': 'number'},
    'mentions_lcr': {'name': 'Mentions Lead Copper Rule', 'key': 'mentions_lcr', 'type': 'number'},
    'mentions_water_loss': {'name': 'Mentions Water Loss', 'key': 'mentions_water_loss', 'type': 'number'},
    'mentions_lead_pipe_removal': {'name': 'Mentions Lead Pipe Removal', 'key': 'mentions_lead_pipe_removal', 'type': 'number'},
    'mentions_water_main_rehabilitation': {'name': 'Mentions Water Main Rehabilitation', 'key': 'mentions_water_main_rehabilitation', 'type': 'number'},
    'mentions_water_main_replacement': {'name': 'Mentions Water Main Replacement', 'key': 'mentions_water_main_replacement', 'type': 'number'},
    'mentions_water_main_length': {'name': 'Water Main Length', 'key': 'mentions_water_main_length', 'type': 'number'},
    'mentions_water_main_improvement': {'name': 'Mentions Water Main Improvement', 'key': 'mentions_water_main_improvement', 'type': 'number'},
    'mentions_WIFIA': {'name': 'Mentions WIFIA', 'key': 'mentions_WIFIA', 'type': 'number'},
    'mentions_WIIP': {'name': 'Mentions Replacement WIIP', 'key': 'mentions_WIIP', 'type': 'number'},
    'mentions_replacement_rate': {'name': 'Mentions Replacement Rate', 'key': 'mentions_replacement_rate', 'type': 'number'},
    'mentions_wooden_pipes': {'name': 'Mentions Wood Pipes', 'key': 'mentions_wooden_pipes', 'type': 'number'},
    'mentions_lead_abatement': {'name': 'Mentions Lead Abatement', 'key': 'mentions_lead_abatement', 'type': 'number'},
    'mentions_lead_reduction': {'name': 'Mentions Lead Reduction', 'key': 'mentions_lead_reduction', 'type': 'number'},
    'mentions_aging_infrastructure': {'name': 'Mentions Aging Infrastructure', 'key': 'mentions_aging_infrastructure', 'type': 'number'},
    'mentions_Resilience': {'name': 'Mentions Resilience', 'key': 'mentions_Resilience', 'type': 'number'},
    'mentions_Earthquake': {'name': 'Mentions Earthquake', 'key': 'mentions_Earthquake', 'type': 'number'},
    'mentions_seismic': {'name': 'Mentions seismic', 'key': 'mentions_seismic', 'type': 'number'},
    // 'is_org_child': {'name': 'Is Child Organization', 'key': 'is_org_child', 'type': 'bool'},
    // 'email_domain': {'name': 'Email Domain', 'key': 'email_domain', 'type': 'string'},
    // 'founded_year': {'name': 'Founded Year', 'key': 'founded_year', 'type': 'number'},
    // 'training_focused_culture': {'name': 'Has Training Focused Culture', 'key': 'training_focused_culture', 'type': 'bool'},
    // 'country': {'name': 'Country', 'key': 'country', 'type': 'string'},
    // 'record_creation': {'name': 'Creation Date', 'key': 'record_creation', 'type': 'date'},
    // 'last_modified': { 'name': 'Last Updated', 'key': 'last_modified', 'type': 'date' },
    // 'company_type': {'name': 'Company Type', 'key': 'company_type', 'type': 'string'},
    // 'employee_growth': {'name': 'Employee Growth', 'key': 'employee_growth', 'type': 'number'},
    // 'employees_at_location': {'name': 'Employees At Location', 'key': 'employees_at_location', 'type': 'number'},
    // 'immediate_parent': {'name': 'Immediate Parent', 'key': 'immediate_parent', 'type': 'string'},
    // 'income_growth': {'name': 'Income Growth %', 'key': 'income_growth', 'type': 'number'},
    // 'is_manufacturing': {'name': 'Is Manufacturing?', 'key': 'is_manufacturing', 'type': 'bool'},
    // 'is_minority_owned': {'name': 'Is Minority Owned?', 'key': 'is_minority_owned', 'type': 'bool'},
    // 'is_woman_owned': {'name': 'Is Woman Owned?', 'key': 'is_woman_owned', 'type': 'bool'},
    // 'line_of_business': {'name': 'Line of Business', 'key': 'line_of_business', 'type': 'string'},
    // 'net_income': {'name': 'Net Income', 'key': 'net_income', 'type': 'number', 'sub_type': 'money'},
    // 'revenue_growth': {'name': 'Revenue Growth', 'key': 'revenue_growth', 'type': 'number'},
    // 'duns_total_employees': {'name': 'Total Employees', 'key': 'duns_total_employees', 'type': 'number'},
    // 'ultimate_parent': {'name': 'Ultimate Parent', 'key': 'ultimate_parent', 'type': 'string'},
    // 'government_association': {'name': 'Goverment Association?', 'key': 'government_association', 'type': 'bool'},
    // 'training_priority': {'name': 'Training Priority?', 'key': 'training_priority', 'type': 'bool'},
    // 'naics_codes': {'name': 'Additional NAICS Codes', 'key': 'naics_codes', 'type': 'list'},
    // 'sic_codes': {'name': 'Additional SIC Codes', 'key': 'sic_codes', 'type': 'list'},
    // 'mentions_milspec': {'name': 'Mentions Milspec', 'key': 'mentions_milspec', 'type': 'bool'},
    // 'mentions_training': {'name': 'Mentions Training', 'key': 'mentions_training', 'type': 'bool'},
    // 'mentions_manufacturing': {'name': 'Mentions Manufacturing', 'key': 'mentions_manufacturing', 'type': 'bool'},
    // 'mentions_fabrication': {'name': 'Mentions Fabrication', 'key': 'mentions_fabrication', 'type': 'bool'},
    // 'mentions_aviation': {'name': 'Mentions Aviation', 'key': 'mentions_aviation', 'type': 'bool'},
    // 'mentions_aerospace': {'name': 'Mentions Aerospace', 'key': 'mentions_aerospace', 'type': 'bool'},
    // 'mentions_food': {'name': 'Mentions Food', 'key': 'mentions_food', 'type': 'bool'},
    // 'mentions_safety': {'name': 'Mentions Safety', 'key': 'mentions_safety', 'type': 'bool'},
    // 'mentions_certificate': {'name': 'Mentions Certificate', 'key': 'mentions_certificate', 'type': 'bool'},
    // 'mentions_certified': {'name': 'Mentions Certified', 'key': 'mentions_certified', 'type': 'bool'},
    // 'mentions_tariff': {'name': 'Mentions Tariff', 'key': 'mentions_tariff', 'type': 'bool'},
    // 'mentions_medical': {'name': 'Mentions Medical', 'key': 'mentions_medical', 'type': 'bool'},
    // 'mentions_compliance': {'name': 'Mentions Compliance', 'key': 'mentions_compliance', 'type': 'bool'},
    // 'mentions_testing': {'name': 'Mentions Testing', 'key': 'mentions_testing', 'type': 'bool'},
    // 'nlp_terms': {'name': 'Keywords', 'key': 'nlp_terms', 'type': 'list'},
    // 'assignee_emails': {'name': 'Assignee Emails', 'key': 'assignee_emails', 'type': 'list'},
    // 'assignee_names': {'name': 'Assignee Names', 'key': 'assignee_names', 'type': 'list'},
    // 'cert_names': {'name': 'Certificate Names', 'key': 'cert_names', 'type': 'list'},
    // 'violation_count': {'name': 'Lifetime Violations', 'key': 'violation_count', 'type': 'number'},
    // 'violation_count_year': {'name': 'Violations Past Year', 'key': 'violation_count_year', 'type': 'number'},
    // 'violation_count_qtr': {'name': 'Violations Past Quarter', 'key': 'violation_count_qtr', 'type': 'number'}
};

export default fieldList;