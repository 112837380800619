import React, {Fragment} from 'react';
import {Button, Table} from 'semantic-ui-react';
import {makeNumberPretty} from '../../../../../util/lib';
import PropTypes from 'prop-types';

class ListComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAll: false
        };
    }

    handleColTransform(transform, value) {
        if (value) {
            switch (transform) {
            case 'parseInt': {
                return parseInt(value);
            }

            case 'date': {
                const date = new Date(Date.parse(value));
                return `${date.getMonth() + 1}/${date.getDay() + 1}/${date.getFullYear()}`;
            }

            default: {
                return value;
            }
            }
        } else return value;
    }

    render() {
        return (
            <Fragment>
                <Table basic='very' celled stacking striped compact fixed>
                    <Table.Header>
                        {this.props.columns.map((col, i) => <Table.HeaderCell key={i}>{col.label}</Table.HeaderCell>)}
                    </Table.Header>
                    {this.props.data && this.props.data.length ?
                        this.props.data.slice(0, this.state.showAll ? this.props.data.length : this.props.showCount || 10).map((item, i) => (
                            <Table.Row key={i}>
                                {this.props.columns.map((col, j) => {
                                    const value = item[col.key];
                                    return (
                                        <Table.Cell singleLine={false} style={{wordWrap: 'break-word'}} key={`${i}-${j}`}>
                                            {col.transform && col.transform === 'link' ?
                                                <a
                                                    target={'blank'}
                                                    href={`https://${value}`}
                                                    key={`${i}-${j}`}
                                                >
                                                    {value}
                                                </a> :
                                                // eslint-disable-next-line no-nested-ternary
                                                `${col.monetary ? '$ ' : ''}${(col.prettify ? makeNumberPretty(value) : col.transform ? this.handleColTransform(col.transform, value) : value) || 'N/A'}`}
                                        </Table.Cell>
                                    );
                                })}
                            </Table.Row>
                        )) :
                        null}
                </Table>
                {this.props.data && this.props.data.length > this.props.showCount ?

                    <Button
                        fluid
                        size='small'
                        basic
                        onClick={() => {
                            this.setState({showAll: !this.state.showAll});
                        }}>
                        {`Show ${this.state.showAll ? 'Less' : 'More'}`}
                    </Button> :
                    null}
            </Fragment>
        );
    }
}

ListComponent.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    showCount: PropTypes.bool
};

export default ListComponent;