import React from 'react';
import {Button, Header, Icon, Modal} from 'semantic-ui-react';
import PropTypes from 'prop-types';

class DeleteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {modalOpen: false};
    }

    handleOpen = () => this.setState({modalOpen: true})

    handleClose = () => this.setState({modalOpen: false})


    render() {
        return (
            <Modal
                open={this.state.modalOpen}
                onClose={this.handleClose}
                trigger={<Button onClick={this.handleOpen} basic color='red'>
                    <Icon name='delete'/>
                    Delete
                </Button>} basic size='small'>
                <Header icon='archive' content='Delete Term'/>
                <Modal.Content>
                    <p>
                        This action cannot be reversed. Are you sure you want to delete this term?
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleClose} basic inverted>
                        Cancel
                    </Button>
                    <Button color='red' inverted onClick={this.props.handleDelete}>
                        <Icon name='trash'/> Yes
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

DeleteModal.propTypes = {
    handleDelete: PropTypes.func
};

export default DeleteModal;