/* eslint-disable no-console */
import React from 'react';
import {
    FlexibleXYPlot,
    LabelSeries,
    XAxis,
    YAxis,
    HorizontalGridLines,
    VerticalBarSeries,
    LineSeries,
    Crosshair,
    VerticalGridLines,
    MarkSeries,
    LineMarkSeries
} from 'react-vis';
import 'react-vis/dist/style.css';
import {makeNumberPretty, toTitleCase} from '../../../../../util/lib';
import PropTypes from 'prop-types';

class ChartComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            crosshairValues: []
        };
    }

    _onNearestX = (value, {index}) => {
        this.setState({crosshairValues: [ {x: this.props.data[index][this.props.chart.x], y: this.props.data[index][this.props.chart.y]} ]});
    };

    _onMouseLeave = () => {
        this.setState({crosshairValues: []});
    };

    render() {
        const margin = {left: 80, right: 40, top: 10, bottom: 40};

        const {data, chart} = this.props;
        switch (chart.type) {
        case 'line': {
            const chartData = data.map(row => ({
                x: parseFloat(row[chart.x]),
                y: parseFloat(row[chart.y])
            }));
            return (
                <FlexibleXYPlot
                    onMouseLeave={this._onMouseLeave}
                    animation
                    margin={margin}
                >
                    <Crosshair values={this.state.crosshairValues} titleFormat={d => ({title: toTitleCase(chart.x.replace(/_/g, ' ')), value: d[0].x})} itemsFormat={d => d.map(dv => ({title: toTitleCase(chart.y.replace(/_/g, ' ')), value: makeNumberPretty(dv.y)}))}/>
                    <VerticalGridLines/>
                    <HorizontalGridLines/>
                    <LineSeries
                        onNearestX={this._onNearestX}
                        data={chartData}/>
                    {chart.x === 'Year' || chart.x === 'year' ?
                        <XAxis tickFormat={v => v.toString()} title='Year'/> :
                        <XAxis title={toTitleCase(chart.x.replace(/_/g, ' '))}/>}

                    <YAxis title={toTitleCase(chart.y.replace(/_/g, ' '))}/>
                </FlexibleXYPlot>
            );
        }

        case 'line-mark': {
            const chartData = data.map(row => ({
                x: parseFloat(row[chart.x]),
                y: parseFloat(row[chart.y])
            }));
            return (
                <FlexibleXYPlot
                    onMouseLeave={this._onMouseLeave}
                    animation
                    margin={margin}
                >
                    <Crosshair values={this.state.crosshairValues} titleFormat={d => ({title: toTitleCase(chart.x.replace(/_/g, ' ')), value: d[0].x})} itemsFormat={d => d.map(dv => ({title: toTitleCase(chart.y.replace(/_/g, ' ')), value: makeNumberPretty(dv.y)}))}/>
                    <VerticalGridLines/>
                    <HorizontalGridLines/>
                    <LineMarkSeries
                        onNearestX={this._onNearestX}
                        data={chartData}/>
                    {chart.x === 'Year' || chart.x === 'year' ?
                        <XAxis tickFormat={v => v.toString()} title='Year'/> :
                        <XAxis title={toTitleCase(chart.x.replace(/_/g, ' '))}/>}

                    <YAxis title={toTitleCase(chart.y.replace(/_/g, ' '))}/>
                </FlexibleXYPlot>
            );
        }

        case 'bar': {
            const chartData = data.map(row => ({
                x: row[this.props.chart.x],
                y: parseFloat(row[this.props.chart.y])
            }));
            console.log(chartData);

            return (
                <FlexibleXYPlot
                    margin={margin}
                    animation
                    xType='ordinal'
                    onMouseLeave={this._onMouseLeave}
                >
                    <Crosshair values={this.state.crosshairValues} titleFormat={d => ({title: toTitleCase(chart.x.replace(/_/g, ' ')), value: d[0].x})} itemsFormat={d => d.map(dv => ({title: toTitleCase(chart.y.replace(/_/g, ' ')), value: makeNumberPretty(dv.y)}))}/>
                    <HorizontalGridLines/>
                    <VerticalBarSeries
                        onNearestX={this._onNearestX}
                        data={chartData}
                        barWidth={0.8}
                        color={'#ffb500'}
                    />
                    <XAxis title={toTitleCase(chart.x.replace(/_/g, ' '))}/>
                    <YAxis title={toTitleCase(chart.y.replace(/_/g, ' '))}/>
                    <LabelSeries data={chartData} getLabel={d => makeNumberPretty(d.y.toString(), 0)} />
                </FlexibleXYPlot>
            );
        }

        case 'mark': {
            const chartData = data.map(row => ({
                x: +new Date(row[this.props.chart.x]),
                y: parseFloat(row[this.props.chart.y]),
                size: 1
            }));
            return (
                <FlexibleXYPlot
                    margin={margin}
                    animation
                    // onMouseLeave={this._onMouseLeave}
                >
                    {/* <Crosshair values={this.state.crosshairValues} titleFormat={d=> ({title:toTitleCase(chart.x.replace(/_/g, ' ')), value:d[0].x})} itemsFormat={d=> d.map(dv=>({title:toTitleCase(chart.y.replace(/_/g, ' ')), value:makeNumberPretty(dv.y)}))}/>*/}
                    <HorizontalGridLines/>
                    <MarkSeries
                        // onNearestX={this._onNearestX}
                        data={chartData}
                        // color={'#ffb500'}
                        // sizeRange={[5, 15]}

                    />
                    <XAxis title={toTitleCase(chart.x.replace(/_/g, ' '))}/>
                    <YAxis title={toTitleCase(chart.y.replace(/_/g, ' '))}/>
                    <LabelSeries data={chartData} getLabel={d => makeNumberPretty(d.y.toString(), 0)} />
                </FlexibleXYPlot>
            );
        }

        default: {
            return (
                <FlexibleXYPlot>
                </FlexibleXYPlot>
            );
        }
        }
    }
}

ChartComponent.propTypes = {
    data: PropTypes.any,
    chart: PropTypes.any
};

export default ChartComponent;