import React from 'react';
import PropTypes from 'prop-types';
import {Segment, Header, Button, Input} from 'semantic-ui-react';
import ChartComponent from './components/ChartComponent';
import ListComponent from './components/ListComponent';

/**
 * Component runs a api call based on the current, then displays the list.
 */

const sortMap = {
    'desc': -1,
    'asc': 1
};

class ProspectProfileCard extends React.Component {
    constructor(props) {
        super(props);

        const data = props.sort && props.data ? props.data.sort((a, b) => (a[props.sort.key] > b[props.sort.key] ? sortMap[props.sort.order] : sortMap[props.sort.order] * -1)) : props.data;

        this.state = {
            data
        };
    }

    handleSearch(e, {value}) {
        if (this.state.data) {
            this.setState({filteredData: this.state.data.filter(col => col[Object.keys(col)[0]].includes(value))});
        }
    }

    render() {
        const displayData = this.state.filteredData || this.state.data;
        return (
            <Segment clearing>
                <Header style={{maxWidth: '78%'}} as='h3'>{this.props.name}</Header>
                <div>
                    <Button basic active={this.state.showSearch}
                        disabled={this.state.showGraph}
                        style={{position: 'absolute', right: '1em', top: '1em'}} size='tiny' icon='search'
                        onClick={() => this.setState({showSearch: !this.state.showSearch})}/>
                    {this.props.chart ?
                        <Button basic active={this.state.showGraph}
                            style={{position: 'absolute', right: '4em', top: '1em'}}
                            size='tiny' icon='chart bar'
                            onClick={() => this.setState({showGraph: !this.state.showGraph})}/> :
                        null
                    }
                </div>
                {this.state.showSearch ?
                    <Input fluid icon={'search'} onChange={(e, v) => this.handleSearch(e, v)}/> :
                    null}
                {this.state.showGraph ?
                    <div style={{width: '100%', height: '100%', minHeight: '20em', display: 'table'}}>
                        <ChartComponent chart={this.props.chart} data={this.state.data}/>
                    </div> :
                    <ListComponent data={displayData} columns={this.props.columns} showCount={this.props.showCount}/>
                }
            </Segment>
        );
    }
}

export default ProspectProfileCard;

// this is apparently important or something
ProspectProfileCard.propTypes = {
    columns: PropTypes.array,
    limited: PropTypes.bool,
    data: PropTypes.array,
    chart: PropTypes.object,
    sort: PropTypes.any,
    showCount: PropTypes.any,
    name: PropTypes.string
};