/* eslint-disable no-console */
import React from 'react';
import {
    Icon,
    Table,
    Checkbox,
    Pagination,
    Grid,
    Rating
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { removeFilter, addFilter, setColumns, setPageSize, setSort, setPageNumber } from '../../../actions';
import { connect } from 'react-redux';
import ErrorBlock from '../../../components/ErrorBlock';
import LoadingTable from './LoadingTable';
import { toTitleCase } from '../../../util/lib';


class ProspectTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            // TODO: Maybe sidebar
            nonShowKeyList: ['_id', 'client_name', 'id', 'org_id'],
        };
    }


    // stay
    handleSort = clickedColumn => () => {
        const { sortDirection } = this.props.prospectList;
        this.props.setSort(clickedColumn, sortDirection === 'ascending' ? 'descending' : 'ascending');
        this.props.setPageNumber(1);
    }

    // TODO: is this only called once?
    // yes I believe it is

    makeHeaderRow = () => (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Select</Table.HeaderCell>
                {[ {
                    key: 'name',
                    text: 'Name',
                    value: 'name'
                }, ...this.props.prospectList.selectedColumns].map(header => <Table.HeaderCell key={header.key}
                    sorted={this.props.prospectList.sortColumn === header.key.toString() ? this.props.prospectList.sortDirection : null}
                    onClick={this.handleSort(header.key.toString())}>{header.text}</Table.HeaderCell>)}
            </Table.Row>
        </Table.Header>
    )

    makeTableRow = rowData => (
        <Table.Row positive={this.props.prospectInteraction.flagged.includes(rowData.org_id)}>
            <Table.Cell collapsing textAlign='center'>
                <Checkbox key={rowData.org_id} onChange={(e, d) => this.props.handleRowExportToggle(d, rowData.org_id)}
                    checked={this.props.selectedForExport.has(rowData.org_id)} />
            </Table.Cell>
            {/* TODO: move column titlecase config to Server*/}
            {[ { key: 'name', text: 'Name', value: 'name' }, ...this.props.prospectList.selectedColumns].map((header, i) => <Table.Cell style={{ cursor: 'pointer' }} onClick={e => {
                if (e.metaKey || e.ctrlKey || e.which === 2) {
                    window.open(`http://localhost:3000/prospect/profile/?id=${ rowData.org_id}`, '_blank');
                } else {
                    this.props.history.push(`/prospect/profile/?id=${ rowData.org_id}`);
                }
            }}
            onAuxClick={() => {
                window.open(`http://localhost:3000/prospect/profile/?id=${ rowData.org_id}`, '_blank');
            }}
            key={i}>
                {header.key === 'name' &&
                            this.props.prospectInteraction.flagged.includes(rowData.org_id) ?
                    <Icon style={{ position: 'absolute', left: '-1em' }} name='flag' color='green' size='small' key={i}/> :
                    null}
                {(rowData[header.key] ? ['name', 'primary_city'].includes(header.key) ? toTitleCase(rowData[header.key].toString()) : rowData[header.key].toString() : 'N/A')}
                {header.key === 'name' &&
                            this.props.prospectInteraction.starred.filter(o => o.org_id === rowData.org_id).length > 0 ?
                    <Rating size='tiny' maxRating={this.props.prospectInteraction.starred.filter(o => o.org_id === rowData.org_id)[0].stars} rating={this.props.prospectInteraction.starred.filter(o => o.org_id === rowData.org_id)[0].stars} style={{ marginLeft: '1em' }} color='yellow' icon='star' key={i}/> :
                    null}
            </Table.Cell>)
            }
        </Table.Row>
    )

    makeTableBody = () => (
        <Table.Body>
            {this.props.prospectData.map(this.makeTableRow)}
        </Table.Body>
    )

    // TODO: the last retrieveData call should be removed when we're storing things properly in state. No API calls for going backwards
    handlePaginationChange = (e, { activePage }) => {
        this.props.setPageNumber(+activePage);
    }

    makePaginationFooter = () => (
        <Grid.Row columns={2}>
            <Grid.Column width={4} textAlign='left'>
                    Viewing {((this.props.prospectList.currentPage || 1) - 1) * this.props.prospectList.pageSize + 1} - {(((this.props.prospectList.currentPage || 1) - 1) * this.props.prospectList.pageSize) + this.props.totalResults} of {this.props.totalRecords}
            </Grid.Column>
            <Grid.Column width={12} textAlign='left'>
                <Pagination
                    // defaultActivePage={1}
                    activePage={this.props.prospectList.currentPage}
                    ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                    firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                    lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                    nextItem={{ content: <Icon name='angle right' />, icon: true }}
                    totalPages={this.props.pageCount || 1}
                    onPageChange={(e, d) => this.handlePaginationChange(e, d)}
                />
            </Grid.Column>
        </Grid.Row>
    )

    render() {

        return (
            <Grid style={{ overflow: 'hidden', minHeight: '80vh', width: '100%' }}>
                <ErrorBlock error={this.state.error} />
                <Grid.Row columns={1}>
                    <Grid.Column width={16}>
                        {this.props.prospectData && !this.props.isLoading ?
                            <Table definition sortable celled striped selectable>
                                {this.makeHeaderRow()}
                                {this.makeTableBody()}
                            </Table> :
                            <LoadingTable columns={6} rows={20} />}
                    </Grid.Column>
                </Grid.Row>
                {this.props.prospectData ? this.makePaginationFooter() : null}
            </Grid>
        );
    }
}

ProspectTable.propTypes = {
    prospectList: PropTypes.object,
    prospectData: PropTypes.any,
    isLoading: PropTypes.bool,
    pageCount: PropTypes.number,
    totalRecords: PropTypes.number,
    totalResults: PropTypes.number,
    prospectInteraction: PropTypes.object,
    selectedForExport: PropTypes.any,
    handleRowExportToggle: PropTypes.func,
    setPageNumber: PropTypes.func,
    setSort: PropTypes.func,
    history: PropTypes.array
};

// eslint-disable-next-line func-style
function mapStateToProps({ prospectList, prospectInteraction }) {
    return { prospectList, prospectInteraction };
}

const mapDispatchToProps = {
    addFilter, removeFilter, setColumns, setPageSize, setSort, setPageNumber
};

export default connect(mapStateToProps, mapDispatchToProps)(ProspectTable);