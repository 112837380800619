import React from 'react';
// Mapbox GL JS Documentation - https://docs.mapbox.com/mapbox-gl-js/api/
import mapboxgl from 'mapbox-gl';
import './ProspectMap.css';
import { Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { setZoom, setCoordinates } from '../../../actions';
import PropTypes from 'prop-types';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

class ProspectMap extends React.Component {
    constructor(props) {
        super(props);
        this.map;
        this.state = {
            // Set to true when user clicks on a feature to open a popup, as opposed to just hovering over to view a popup.
            // Keeps track of whether there is a popup that needs to be closed in certain situations.
            featureClicked: false,
        };
    }

    componentDidMount() {
        const { setCoordinates, setZoom } = this.props; // Redux actions
        const { zoomThreshold } = this.props;
        const { lng, lat, zoom } = this.props.prospectList;

        // This accounts for user accounts that were created before the map component was built.
        // The metadata for those users were not initialized with lng, lat, and zoom.
        const initialLng = lng ? lng : -98.427340;
        const initialLat = lat ? lat : 39.995133;
        const initialZoom = zoom ? zoom : 2.5;

        this.map = new mapboxgl.Map({
            // HTML element containing the map
            container: this.mapSegment,
            // See map style options at https://docs.mapbox.com/mapbox-gl-js/api/#map
            style: 'mapbox://styles/mapbox/light-v9',
            center: [initialLng, initialLat],
            zoom: initialZoom
        });

        this.map.on('load', async () => {
            // Scale bar and zoom buttons
            const scale = new mapboxgl.ScaleControl({ unit: 'imperial' });
            const nav = new mapboxgl.NavigationControl();

            this.map.addControl(scale);
            this.map.addControl(nav, 'top-right');
            // Populate the mapFeatures state slice in index.js with fresh data, which will
            // trigger the componentDidUpdate() of this component to draw the map features.
            await this.props.retrieveMapData();
        });

        // ----- Update view extent (center coordinates and zoom level) in Redux store
        // whenever it changes, so that it persists upon page refresh, logging out and in,
        // and toggling between the list and map view.

        const handleMapMove = () => {
            setCoordinates(Number(this.map.getCenter().lat.toFixed(4)), Number(this.map.getCenter().lng.toFixed(4)));
            setZoom(Number(this.map.getZoom().toFixed(2)));
        };

        this.map.on('moveend', () => { handleMapMove(); });
        this.map.on('zoomend', () => {
            handleMapMove();
            // Remove any popups if zoomed out to a certain extent (maybe not necessary?)
            if (this.props.prospectList.zoom < zoomThreshold) {
                popup.remove();
            }
        });

        // ----- Popup for when user hovers over or clicks a feature

        const popup = new mapboxgl.Popup({
            closeOnClick: false
        });

        const createPopup = popupLocation => {
            const { primary_state, primary_city, address_line1, address_line2, org_id, name } = popupLocation.properties;
            const formattedAddress = () => {
                const addressArr = [];
                if (address_line1) addressArr.push(`${address_line1}<br/>`);
                if (address_line2) addressArr.push(`${address_line2}<br/>`);
                if (primary_city) addressArr.push(`${primary_city} `);
                if (primary_state) addressArr.push(primary_state);

                return addressArr.join('');
            };

            popup.setLngLat(popupLocation.geometry.coordinates)
                // Per discussion with Aditya, using <a href=...> instead of onClick for the link below because popup.setHTML manipulates the DOM directly and doesn't play nicely with React router?
                .setHTML(`
                    <h3><a class="popupLink" target="_blank" href=${`/prospect/profile/?id=${org_id.toString()}`}>${name}</a></h3>
                    <h4>${formattedAddress()}</h4>
                `)
                .addTo(this.map);
        };

        popup.on('close', () => {
            this.setState({featureClicked: false});
        });

        // ----- Event handlers for Clusters layer

        this.map.on('mouseenter', 'clusters', () => {
            this.map.getCanvas().style.cursor = 'pointer';
        });

        this.map.on('mouseleave', 'clusters', () => {
            this.map.getCanvas().style.cursor = '';
        });

        // When a user clicks on a cluster, zoom in just enough to separate that cluster
        this.map.on('click', 'clusters', event => {
            popup.remove();
            const features = this.map.queryRenderedFeatures(event.point, { layers: [ 'clusters' ] });
            const clusterId = features[0].properties.cluster_id;
            this.map.getSource('mapFeatures').getClusterExpansionZoom(clusterId, (err, zoom) => {
                if (err) return;
                this.map.easeTo({
                    center: features[0].geometry.coordinates,
                    zoom: zoom
                });
            });
        });

        // ----- Event handlers for Features layer (unclustered dots)

        this.map.on('mouseenter', 'features', event => {
            this.map.getCanvas().style.cursor = 'pointer';
            const { zoom } = this.props.prospectList;
            // Display popup over feature on hover when zoomed in
            if (this.state.featureClicked === false && zoom >= zoomThreshold) {
                const features = this.map.queryRenderedFeatures(event.point, { layers: [ 'features' ] });
                if (features.length && features[0].properties.name) {
                    createPopup(features[0]);
                }
            }
        });

        this.map.on('mouseleave', 'features', () => {
            this.map.getCanvas().style.cursor = '';
            if (this.state.featureClicked === false) popup.remove();
        });

        // When user clicks on a feature:
        // If already zoomed in, stay at the current zoom level, center in on clicked feature, and display a popup.
        // If not, center and zoom in on the clicked feature to a level just beyond the threshold.
        this.map.on('click', 'features', event => {
            const { zoom } = this.props.prospectList;
            const destinationZoom = zoom >= zoomThreshold ? zoom : zoomThreshold + 0.5;
            const features = this.map.queryRenderedFeatures(event.point, { layers: [ 'features' ] });
            if (features.length) {
                const clickedPoint = features[0];
                this.map.flyTo({
                    center: clickedPoint.geometry.coordinates,
                    zoom: destinationZoom
                });
                // TODO: The if statement checks if the map has already fetched the prospect name
                // (which only happens when the map is zoomed in) and only displays a popup in
                // that case. There is probably a better way to do this.
                if (clickedPoint.properties.name) {
                    createPopup(clickedPoint);
                    this.setState({featureClicked: true});
                }
            }
        });
    }

    componentDidUpdate(prevProps) {
        // If it's the first time loading the map, just draw the map
        if (this.props.mapFeatures !== prevProps.mapFeatures && prevProps.mapFeatures === undefined) {
            this.drawMap();
        // If updating the map, need to remove all existing layers and map source before redrawing
        } else if (this.props.mapFeatures !== prevProps.mapFeatures) {
            if (this.map.getLayer('clusters')) this.map.removeLayer('clusters');
            if (this.map.getLayer('cluster-count')) this.map.removeLayer('cluster-count');
            if (this.map.getLayer('features')) this.map.removeLayer('features');
            if (this.map.getSource('mapFeatures')) this.map.removeSource('mapFeatures');
            this.drawMap();
        }

        // Makes the map auto-adjust its size depending on the width of the container,
        // e.g., when the sidebar is toggled in and out of view.
        if (this.props.sidebarVisible !== prevProps.sidebarVisible) {
            this.map.resize();
        }
    }

    // Add layers, following example at https://docs.mapbox.com/mapbox-gl-js/example/cluster/

    drawMap = () => {
        this.map.addSource('mapFeatures', {
            type: 'geojson',
            data: this.props.mapFeatures,
            cluster: true,
            clusterMaxZoom: 14,
            clusterRadius: 50
        });

        // Cluster layer (just the circles, not the numbers)
        this.map.addLayer({
            id: 'clusters',
            type: 'circle',
            source: 'mapFeatures',
            filter: ['has', 'point_count'],
            paint: {
                'circle-color': '#f8b000',
                'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    20, 10,  // 20 px if 10 or less features in cluster
                    30, 50,  // 30 px if 10-50 features in cluster
                    40, 100,  // 40 px if 50-100 features in cluster
                    50  // 50 px for 100 or more features in cluster
                ],
                'circle-opacity': 0.75,
                'circle-stroke-width': 1,
                'circle-stroke-color': 'white'
            }
        });

        // Cluster count layer (just the numbers on top of the clusters)
        this.map.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'mapFeatures',
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 14
            }
        });

        // Features layer (non-clustered points)
        this.map.addLayer({
            id: 'features',
            type: 'circle',
            source: 'mapFeatures',
            filter: ['!', ['has', 'point_count'] ],
            paint: {
                'circle-color': '#f8b000',
                'circle-radius': 7,
                'circle-stroke-width': 1,
                'circle-stroke-color': 'white'
            }
        });
    }

    render() {
        // const mapWidth = this.props.sidebarVisible ? '75%' : '90%';
        return (
            <Segment
                style={{ width: '100%', height: '80vh'}}
                className='mapSegment' >
                <div ref={element => this.mapSegment = element} className='map' />
            </Segment >
        );
    }
}

ProspectMap.propTypes = {
    setCoordinates: PropTypes.func,
    setZoom: PropTypes.func,
    zoomThreshold: PropTypes.number,
    prospectList: PropTypes.object,
    retrieveMapData: PropTypes.func,
    sidebarVisible: PropTypes.bool,
    mapFeatures: PropTypes.any,

};

// eslint-disable-next-line func-style
function mapStateToProps({ prospectList }) {
    return { prospectList };
}

const mapDispatchToProps = { setZoom, setCoordinates };

export default connect(mapStateToProps, mapDispatchToProps)(ProspectMap);