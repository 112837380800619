/* eslint-disable func-style, no-unused-vars, arrow-body-style */
import React from 'react';
import {Menu} from 'semantic-ui-react';
function TopMenu(props) {
    return (
        <Menu secondary pointing fluid>
            {/* <Menu.Item>*/}
            {/*    <Header as='h4'>Account</Header>*/}
            {/* </Menu.Item>*/}
            <Menu.Item
                name='account'
                active={true}
                onClick={() => { return; }}
            />
            {/* <Menu.Item>*/}
            {/*    <Header as='h4'>Billing</Header>*/}
            {/* </Menu.Item>*/}
            <Menu.Item
                name='billing'
                disabled
                onClick={() => { return; }}
            />
            {/* <Menu.Item*/}
            {/*    name='zapier'*/}
            {/*    disabled*/}
            {/*    onClick={()=>{return}}*/}
            {/* />*/}
            {/* <Menu.Item*/}
            {/*    name='hubspot'*/}
            {/*    disabled*/}
            {/*    onClick={()=>{return}}*/}
            {/* />*/}
            {/* <Menu.Item>*/}
            {/*    <Header as='h4'>Administration</Header>*/}
            {/* </Menu.Item>*/}
            {/* <Menu.Item*/}
            {/*    name='user-management'*/}
            {/*    disabled*/}
            {/*    onClick={()=>{return}}*/}
            {/* />*/}
        </Menu>
    );

}

export default TopMenu;