/* eslint-disable no-param-reassign */
import {
    ADD_FILTER,
    EDIT_FILTER,
    REMOVE_FILTER,
    SET_COLUMNS,
    SET_PAGE_NUMBER,
    SET_PAGE_SIZE, SET_PROSPECT_LIST_STATE,
    SET_SORT,
    TOGGLE_MAP_VIEW, SET_ZOOM, SET_COORDINATES
} from '../actions/types';

import {setUserState} from '../util/lib';


const initialState = {
    filters: [],
    selectedColumns: [
        {
            key: 'primary_city',
            text: 'City',
            value: 'primary_city'
        }, {
            key: 'primary_state',
            text: 'State',
            value: 'primary_state'
        }
    ],
    currentPage: 1,
    pageSize: 15,
    sortColumn: 'name',
    direction: 'ascending',
    mapView: false,
    zoom: 2.5,
    lng: -98.427340,
    lat: 39.995133
};

export default function(state = initialState, action) {
    switch (action.type) {
    case ADD_FILTER: {
        state = {
            ...state,
            filters: [...state.filters, action.payload]
        };
        break;
    }

    case REMOVE_FILTER: {
        const {filters} = state;
        if(filters.length === 2) {
            filters[0].org = false;
        }

        // filters.splice(, 1);
        state = {
            ...state,
            filters: [...state.filters.slice(0, action.payload.index), ...state.filters.slice(action.payload.index + 1, state.filters.length)]
        };
        break;
    }

    case EDIT_FILTER: {
        state = {
            ...state,
            filters: [...state.filters.slice(0, action.payload.index), action.payload.filter, ...state.filters.slice(action.payload.index + 1, state.filters.length)]
        };
        break;
    }

    case SET_COLUMNS: {
        state = {
            ...state,
            selectedColumns: action.payload.columns
        };
        break;
    }

    case SET_PAGE_SIZE: {
        state = {
            ...state,
            pageSize: action.payload.pageSize
        };
        break;
    }

    case SET_SORT: {
        state = {
            ...state,
            sortColumn: action.payload.sortColumn,
            sortDirection: action.payload.sortDirection,
        };
        break;
    }

    case SET_PAGE_NUMBER: {
        state = {
            ...state,
            currentPage: action.payload.pageNumber
        };
        break;
    }

    case TOGGLE_MAP_VIEW: {
        state = {
            ...state,
            mapView: action.payload.mapView
        };
        break;
    }

    case SET_ZOOM: {
        state = {
            ...state,
            zoom: action.payload.zoom
        };
        break;
    }

    case SET_COORDINATES: {
        state = {
            ...state,
            lat: action.payload.lat,
            lng: action.payload.lng
        };
        break;
    }

    case SET_PROSPECT_LIST_STATE: {
        state = action.payload.prospectList;

        // Don't refresh server state when setting, so return
        return state;
    }

    default:
        return state;
    }

    setUserState({prospectList: state});

    return state;
}

