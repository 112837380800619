/* eslint-disable no-param-reassign */
const decodeQueryString = queryString => {

    if (!queryString) {
        queryString = window.location.search;
    }

    const queryStrings = {};
    // ? should be decoded
    if (queryString.indexOf('?') > -1) {
        queryString = queryString.replace('?', '');
    }

    queryString.split('&').forEach(pair => {
        // let [key, value] = pair.split('='); // key="selectedDate" value="2018"
        // TODO check length?
        const res = pair.split('=');
        queryStrings[(res[0] || '').toString()] = decodeURIComponent(res[1] || '');
    });

    return queryStrings;
};

exports.decodeQueryString = decodeQueryString;