/* eslint-disable */
import React from 'react'; // eslint-disable-line no-unused-vars

import {ConfirmSignUp} from 'aws-amplify-react';

import AuthWrapper from "./components/AuthWrapper";

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _AmplifyUI = require('aws-amplify-react/dist/AmplifyUI');

var _react2 = _interopRequireDefault(React);

var _core = require('@aws-amplify/core');

class CustomConfirmSignUp extends ConfirmSignUp
{

    constructor(props)
    {

        super(props);

        this.superShowComponent = this.showComponent;

        this.showComponent = (theme) =>
        {

            if (window.document)
            {

                window.document.title = 'Confirm Sign Up | ' + (process.env['REACT_APP_SITE_TITLE'] || 'Wheelhouse Boilerplate App');
            }

            var _this4 = this;

            var hide = this.props.hide;

            var username = this.usernameFromAuthData();

            if (hide && hide.includes(ConfirmSignUp)) {
                return null;
            }

            return (
                <AuthWrapper>

                    {
                        _react2.default.createElement(
                            _AmplifyUI.FormSection,
                            { theme: theme },
                            _react2.default.createElement(
                                _AmplifyUI.SectionHeader,
                                { theme: theme },
                                _core.I18n.get('Confirm'),
                                ' ',
                                _core.I18n.get('Sign Up')
                            ),
                            _react2.default.createElement(
                                _AmplifyUI.SectionBody,
                                { theme: theme },
                                username ? _react2.default.createElement(
                                    _AmplifyUI.MessageRow,
                                    null,
                                    username
                                ) : _react2.default.createElement(_AmplifyUI.InputRow, {
                                    placeholder: _core.I18n.get('Username'),
                                    theme: theme,
                                    key: 'username',
                                    name: 'username',
                                    onChange: this.handleInputChange
                                }),
                                _react2.default.createElement(_AmplifyUI.InputRow, {
                                    autoFocus: true,
                                    placeholder: _core.I18n.get('Code'),
                                    theme: theme,
                                    key: 'code',
                                    name: 'code',
                                    autoComplete: 'off',
                                    onChange: this.handleInputChange
                                }),
                                _react2.default.createElement(
                                    _AmplifyUI.Link,
                                    { theme: theme, onClick: function onClick() {
                                            return _this4.changeState('signIn');
                                        } },
                                    _core.I18n.get('Back to Sign In')
                                ),
                                _react2.default.createElement(
                                    _AmplifyUI.ActionRow,
                                    { theme: theme },
                                    _react2.default.createElement(
                                        _AmplifyUI.Button,
                                        { theme: theme, onClick: this.confirm },
                                        _core.I18n.get('Confirm')
                                    ),
                                    _react2.default.createElement(_AmplifyUI.Space, { theme: theme }),
                                    _react2.default.createElement(
                                        _AmplifyUI.Button,
                                        { theme: theme, onClick: this.resend },
                                        _core.I18n.get('Resend Code')
                                    )
                                )
                            ),
                            // _react2.default.createElement(
                            //     _AmplifyUI.SectionFooter,
                            //     { theme: theme },
                            //     _react2.default.createElement(
                            //         _AmplifyUI.Link,
                            //         { theme: theme, onClick: function onClick() {
                            //                 return _this4.changeState('signIn');
                            //             } },
                            //         _core.I18n.get('Back to Sign In')
                            //     )
                            // )
                        )
                    }

                </AuthWrapper>
            );
        };

        // this.superComponentDidMount = this.componentDidMount;
        //
        // this.componentDidMount = () =>
        // {
        //
        //     if (typeof this.superComponentDidMount === 'function')
        //     {
        //
        //         this.superComponentDidMount();
        //     }
        //
        //     if (window.document)
        //     {
        //
        //         window.document.title = 'Confirm Sign Up | ' + (process.env['REACT_APP_SITE_TITLE'] || 'Wheelhouse Boilerplate App');
        //     }
        // };
    }
}

export default CustomConfirmSignUp;