/* eslint-disable no-console */
import React from 'react';
import {Checkbox, Form, Grid, Header, Table, Segment, Message} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {toTitleCase} from '../../../util/lib';
import ErrorBlock from '../../../components/ErrorBlock';
import {Auth} from 'aws-amplify';
import PropTypes from 'prop-types';

class AccountSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            given_name: this.props.auth ? this.props.auth.given_name : null,
            family_name: this.props.auth ? this.props.auth.family_name : null
        };
    }

    handleChange = (e, {name, value}) => {
        const {state} = this;
        state[name] = value;
        this.setState({
            ...this.state,
            ...state
        });
    }

    getPasswordError = () => {
        const {np, npr, op} = this.state;
        if (!np || !npr || !op) {
            return 'Please fill out all required fields';
        }

        if (np !== npr) {
            return 'Passwords do not Match';
        }

        if (np.length < 8) {
            return 'Password too short, must be at least 8 characters';
        }

        if (!np.match(/[A-Z]/g)) {
            return 'Password must contain an Uppercase character';
        }

        if (!np.match(/[a-z]/g)) {
            return 'Password must contain an lowercase character';
        }

        if (!np.match(/[0-9]/g)) {
            return 'Password must contain a number';
        }

        // eslint-disable-next-line no-useless-escape
        if (!np.match(/[$-/:-?{-~!"^_`\[\]]/g)) {
            return 'Password must contain a symbol';
        }

        return true;
    }
    // eslint-disable-next-line require-await
    handlePasswordChange = async () => {
        Auth.currentAuthenticatedUser()
            .then(user => Auth.changePassword(user, this.state.op, this.state.np))
            .then(() => this.setState({op: null, np: null, npr: null, passwordChangeSuccess: true}))
            .catch(() => this.setState({passwordChangeSuccess: false}));
    }

    handleAttributeSave = async () => {
        this.setState({generalInformationLoading: true});
        try {
            const user = await Auth.currentAuthenticatedUser();
            const result = await Auth.updateUserAttributes(user, {
                'given_name': this.state.given_name,
                'family_name': this.state.family_name
            });
            if (result === 'SUCCESS') {
                this.setState({generalInformationLoading: false, generalInformationChangeSuccess: true});
            } else {
                this.setState({generalInformationLoading: false, generalInformationChangeSuccess: false});
            }
        } catch (e) {
            this.setState({generalInformationLoading: false, generalInformationChangeSuccess: false});
        }
    }

    render() {
        if (this.props && this.props.auth) {
            const {np, npr, op, passwordChangeSuccess, given_name, family_name, generalInformationChangeSuccess} = this.state;
            const {email, group} = this.props.auth;
            let password_valid = true;
            if (npr || np || op) {
                password_valid = this.getPasswordError() === true;
            }

            console.log(passwordChangeSuccess, password_valid);

            return (
                <Grid>
                    <Grid.Row>
                        <Grid.Column textAlign='center'>
                            <Header as='h1'>Account Settings - WIP</Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={12}>
                            <Segment attached='top'>
                                <Header as='h3'>General Information</Header>
                                {generalInformationChangeSuccess === false || generalInformationChangeSuccess === true ?
                                    <Message
                                        error={generalInformationChangeSuccess === false}
                                        success={generalInformationChangeSuccess === true}
                                        content={generalInformationChangeSuccess === false ? 'Update Failed' : 'Information Successfully Updated'}
                                    /> :
                                    null}
                                <Form>
                                    <Form.Field width={8}>
                                        <label>Email Address</label>
                                        <Form.Input value={email} loading={!email}
                                            disabled
                                        />
                                    </Form.Field>
                                    <Form.Group>
                                        <Form.Field width={8}>
                                            <label>First Name</label>
                                            <Form.Input value={given_name} name={'given_name'}
                                                onChange={(e, v) => this.handleChange(e, v)}/>
                                        </Form.Field>
                                        <Form.Field width={8}>
                                            <label>Last Name</label>
                                            <Form.Input value={family_name} name={'family_name'}
                                                onChange={(e, v) => this.handleChange(e, v)}/>
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Field
                                        control={Checkbox}
                                        label={<label>Receive E-mail Updates</label>}
                                    />
                                    <Form.Button onClick={() => this.handleAttributeSave()}
                                        loading={this.state.generalInformationLoading}>Save</Form.Button>
                                </Form>
                            </Segment>
                            <Segment attached='bottom'>
                                <Header as='h3'>Change Password</Header>
                                <Form error={!password_valid}>
                                    {!password_valid || (passwordChangeSuccess === false || passwordChangeSuccess === true) ?
                                        <Message
                                            error={!password_valid || passwordChangeSuccess === false}
                                            // success = {passwordChangeSuccess === true}
                                            content={password_valid ? passwordChangeSuccess === true ? 'Password Changed' : 'Old Password Incorrect' : () => this.getPasswordError()}
                                            // content={!password_valid ? () => this.getPasswordError() : passwordChangeSuccess === true ? 'Password Changed' : 'Old Password Incorrect'}
                                        /> :

                                        null}
                                    <Form.Field>
                                        <label>Old Password</label>
                                        <Form.Input value={op} name='op' onChange={(e, v) => this.handleChange(e, v)}
                                            type='password'/>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>New Password</label>
                                        <Form.Input value={np} onChange={(e, v) => this.handleChange(e, v)} name='np'
                                            type='password'/>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Confirm New Password</label>
                                        <Form.Input value={npr} onChange={(e, v) => this.handleChange(e, v)} name='npr'
                                            type='password'/>
                                    </Form.Field>
                                    <Form.Button disabled={!password_valid} onClick={() => this.handlePasswordChange()}>Update
                                        Password</Form.Button>
                                </Form>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Segment>
                                <Header as='h3' textAlign='center'>Organization</Header>
                                <Table basic='very'>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>
                                                <Header as='h4'>
                                                    Name
                                                </Header>
                                            </Table.Cell>
                                            <Table.Cell>
                                                {toTitleCase(group)}
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>
                                                <Header as='h4'>
                                                    Administrator
                                                </Header>
                                            </Table.Cell>
                                            <Table.Cell>
                                                Vincent Berube
                                            </Table.Cell>
                                        </Table.Row>
                                        {/* <Table.Row>*/}
                                        {/*    <Table.Cell>*/}
                                        {/*        <Header as='h4'>*/}
                                        {/*            Licenses Used*/}
                                        {/*        </Header>*/}
                                        {/*    </Table.Cell>*/}
                                        {/*    <Table.Cell>*/}
                                        {/*        1/1*/}
                                        {/*    </Table.Cell>*/}
                                        {/* </Table.Row>*/}
                                        {/* <Table.Row>*/}
                                        {/*    <Table.Cell>*/}
                                        {/*        <Header as='h4'>*/}
                                        {/*            Subscription Expiry*/}
                                        {/*        </Header>*/}
                                        {/*    </Table.Cell>*/}
                                        {/*    <Table.Cell>*/}
                                        {/*        12/1/19*/}
                                        {/*    </Table.Cell>*/}
                                        {/* </Table.Row>*/}
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            );
        } else {
            return (
                <ErrorBlock error={'Unable to retrieve user details'}/>
            );
        }

    }
}

AccountSettings.propTypes = {
    auth: PropTypes.object,

};

// Personal Informati
// Password Requirements (updated 2020-01-21)
// Must be at least 12 characters

// eslint-disable-next-line func-style
function mapStateToProps({auth}) {
    return {auth};
}

export default connect(mapStateToProps)(AccountSettings);