import React from 'react';
import MapComponent from './components/MapComponent';
import PropTypes from 'prop-types';

const ProspectProfileMap = props => (
    <div style={{height: '200px'}}>
        <MapComponent
            lng={props.geo.lon}
            lat={props.geo.lat}
        />
    </div>
);

ProspectProfileMap.propTypes = {
    geo: PropTypes.Object
};

export default ProspectProfileMap;