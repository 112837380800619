import React from 'react';
import ErrorBlock from '../components/ErrorBlock';
import {Container} from 'semantic-ui-react';

const {decodeQueryString} = require('../util/queryString');

/**
 * Displays the 404 page, takes in the error param if there's a specific message to be sent.
 * @queryStringParam error {string} error message to be displayed, has default.
 */
class NotFound extends React.Component {
    constructor(props) {
        super(props);

        if (!this.state) this.state = {};

        const qs = decodeQueryString();
        if (qs && qs['error']) this.state.error = qs['error'];
    }

    render = () => (
        <Container>
            <ErrorBlock error={this.state.error || 'Page Not Found'}/>
        </Container>
    );
}


export default NotFound;
