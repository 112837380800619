/* eslint-disable */
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */
var Container = exports.Container = {
    height: '100%'
};

var NavBar = exports.NavBar = {
    position: 'relative',
    border: '1px solid transparent',
    borderColor: '#e7e7e7'
};

var NavRight = exports.NavRight = {
    textAlign: 'right'
};

var Nav = exports.Nav = {
    margin: '7.5px'
};

var NavItem = exports.NavItem = {
    display: 'inline-block',
    padding: '10px 5px',
    lineHeight: '20px'
};

var NavButton = exports.NavButton = {
    display: 'inline-block',
    padding: '6px 12px',
    marginTop: '8px',
    marginBottom: '8px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    backgroundImage: 'none',
    border: '1px solid transparent',
    borderRadius: '4px',
    color: '#333',
    backgroundColor: '#fff',
    borderColor: '#ccc'
};

var FormContainer = exports.FormContainer = {
    textAlign: 'center'
};

var FormSection = exports.FormSection = {
    // marginBottom: '20px',
    // backgroundColor: '#fff',
    // border: '1px solid #ddd',
    // borderRadius: '4px',
    // textAlign: 'left',
    // width: '400px',
    // display: 'inline-block'
};

var ErrorSection = exports.ErrorSection = {
    marginBottom: '20px',
    color: '#fff',
    backgroundColor: '#2D393B',
    border: '1px solid #2D393B',
    borderRadius: '4px',
    textAlign: 'center'
};

var SectionHeader = exports.SectionHeader = {
    color: '#000',
    fontWeight: 700,
    fontSize: '1.3em',
    // backgroundColor: '#FFB500',
    borderColor: '#FFF',
    padding: '10px 15px',
    borderBottom: '1px solid transparent',
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    textAlign: 'center'
};

var SectionFooter = exports.SectionFooter = {
    color: '#333',
    backgroundColor: '#f5f5f5',
    padding: '10px 15px',
    borderTop: '1px solid #ddd',
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px'
};

var SectionBody = exports.SectionBody = {
    padding: '15px'
};

var FormRow = exports.FormRow = {
    marginBottom: '15px'
};

var ActionRow = exports.ActionRow = {
    marginBottom: '15px'
};

var InputRow = exports.InputRow = {

}

var Input = exports.Input = {
    "backgroundAttachment": "scroll",
    "backgroundClip": "border-box",
    "backgroundColor": "rgb(255, 255, 255)",
    "backgroundImage": "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAkCAYAAADo6zjiAAAAAXNSR…XF8MaClwaeROWRA7nk+tUnyzGvZrKg0/40gdME/t8EvgG0/NOS6v9NHQAAAABJRU5ErkJggg==)",
    "backgroundOrigin": "padding-box",
    "backgroundPositionX": "98%",
    "backgroundPositionY": "50%",
    "backgroundRepeatX": "",
    "backgroundRepeatY": "",
    "backgroundSize": "16px 18px",
    "borderBottomColor": "rgba(34, 36, 38, 0.15)",
    "borderBottomLeftRadius": "4px",
    "borderBottomRightRadius": "4px",
    "borderBottomStyle": "solid",
    "borderBottomWidth": "0.994318px",
    "borderImageOutset": "0px",
    "borderImageRepeat": "stretch",
    "borderImageSlice": "100%",
    "borderImageSource": "none",
    "borderImageWidth": "1",
    "borderLeftColor": "rgba(34, 36, 38, 0.15)",
    "borderLeftStyle": "solid",
    "borderLeftWidth": "0.994318px",
    "borderRightColor": "rgba(34, 36, 38, 0.15)",
    "borderRightStyle": "solid",
    "borderRightWidth": "0.994318px",
    "borderTopColor": "rgba(34, 36, 38, 0.15)",
    "borderTopLeftRadius": "4px",
    "borderTopRightRadius": "4px",
    "borderTopStyle": "solid",
    "borderTopWidth": "0.994318px",
    "boxShadow": "rgba(0, 0, 0, 0) 0px 0px 0px 0px inset",
    "boxSizing": "border-box",
    "color": "rgba(0, 0, 0, 0.87)",
    "cursor": "auto",
    "display": "block",
    "flexBasis": "auto",
    "flexGrow": "1",
    "flexShrink": "0",
    "fontFamily": "Lato, \"Helvetica Neue\", Arial, Helvetica, sans-serif",
    "fontSize": "14px",
    "fontStretch": "100%",
    "fontStyle": "normal",
    "fontVariantCaps": "normal",
    "fontVariantEastAsian": "normal",
    "fontVariantLigatures": "normal",
    "fontVariantNumeric": "normal",
    "fontWeight": "400",
    "height": "37.3295px",
    "letterSpacing": "normal",
    "lineHeight": "17px",
    "marginBottom": "0px",
    "marginLeft": "0px",
    "marginRight": "0px",
    "marginTop": "0px",
    "maxWidth": "100%",
    "outlineColor": "rgba(0, 0, 0, 0.87)",
    "outlineStyle": "none",
    "outlineWidth": "0px",
    "overflowX": "visible",
    "overflowY": "visible",
    "paddingBottom": "9.5px",
    "paddingLeft": "14px",
    "paddingRight": "14px",
    "paddingTop": "9.5px",
    "textAlign": "left",
    "textIndent": "0px",
    "textRendering": "auto",
    "textShadow": "none",
    "textSizeAdjust": "100%",
    "textTransform": "none",
    "transitionDelay": "0s, 0s",
    "transitionDuration": "0.1s, 0.1s",
    "transitionProperty": "color, border-color",
    "transitionTimingFunction": "ease, ease",
    "verticalAlign": "top",
    "width": "100%",
    "wordSpacing": "0px",
    "writingMode": "horizontal-tb"
};

var Button = exports.Button = {
    "alignItems": "flex-start",
    "backgroundAttachment": "scroll",
    "backgroundClip": "border-box",
    "backgroundColor": "rgb(14, 110, 184)",
    "backgroundImage": "none",
    "backgroundOrigin": "padding-box",
    "backgroundPositionX": "0%",
    "backgroundPositionY": "0%",
    "backgroundRepeatX": "",
    "backgroundRepeatY": "",
    "backgroundSize": "auto",
    "borderBottomColor": "rgb(255, 255, 255)",
    "borderBottomLeftRadius": "4px",
    "borderBottomRightRadius": "4px",
    "borderBottomStyle": "none",
    "borderBottomWidth": "0px",
    "borderImageOutset": "0px",
    "borderImageRepeat": "stretch",
    "borderImageSlice": "100%",
    "borderImageSource": "none",
    "borderImageWidth": "1",
    "borderLeftColor": "rgb(255, 255, 255)",
    "borderLeftStyle": "none",
    "borderLeftWidth": "0px",
    "borderRightColor": "rgb(255, 255, 255)",
    "borderRightStyle": "none",
    "borderRightWidth": "0px",
    "borderTopColor": "rgb(255, 255, 255)",
    "borderTopLeftRadius": "4px",
    "borderTopRightRadius": "4px",
    "borderTopStyle": "none",
    "borderTopWidth": "0px",
    "boxShadow": "rgba(34, 36, 38, 0.15) 0px 0px 0px 0px inset",
    "boxSizing": "border-box",
    "color": "rgb(255, 255, 255)",
    "cursor": "pointer",
    "display": "block",
    "fontFamily": "Lato, \"Helvetica Neue\", Arial, Helvetica, sans-serif",
    "fontSize": "16px",
    "fontStretch": "100%",
    "fontStyle": "normal",
    "fontVariantCaps": "normal",
    "fontVariantEastAsian": "normal",
    "fontVariantLigatures": "normal",
    "fontVariantNumeric": "normal",
    "fontWeight": "700",
    "height": "41.125px",
    "letterSpacing": "normal",
    "lineHeight": "16px",
    "marginBottom": "0px",
    "marginLeft": "0px",
    "marginRight": "4px",
    "marginTop": "0px",
    "minHeight": "16px",
    "outlineColor": "rgb(255, 255, 255)",
    "outlineStyle": "none",
    "outlineWidth": "0px",
    "overflowX": "visible",
    "overflowY": "visible",
    "paddingBottom": "12.5714px",
    "paddingLeft": "24px",
    "paddingRight": "24px",
    "paddingTop": "12.5714px",
    "textAlign": "center",
    "textDecorationColor": "rgb(255, 255, 255)",
    "textDecorationLine": "none",
    "textDecorationStyle": "solid",
    "textIndent": "0px",
    "textRendering": "auto",
    "textShadow": "none",
    "textSizeAdjust": "100%",
    "textTransform": "none",
    "transitionDelay": "0s, 0s, 0s, 0s, 0s",
    "transitionDuration": "0.1s, 0.1s, 0.1s, 0.1s, 0.1s",
    "transitionProperty": "opacity, background-color, color, box-shadow, background",
    "transitionTimingFunction": "ease, ease, ease, ease, ease",
    "userSelect": "none",
    "verticalAlign": "baseline",
    "width": "100%",
    "willChange": "auto",
    "wordSpacing": "0px",
    "writingMode": "horizontal-tb",
    "WebkitAppearance": "none",
    "WebkitTapHighlightColor": "rgba(0, 0, 0, 0)",
    "WebkitBorderImage": "none"
};

var SignInButton = exports.SignInButton = {
    position: 'relative',
    padding: '6px 12px 6px 44px',
    fontSize: '14px',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    width: '100%',
    marginTop: '2px',
    '#google_signin_btn': {
        color: '#fff',
        backgroundColor: '#dd4b39',
        borderColor: 'rgba(0,0,0,0.2)'
    },
    '#facebook_signin_btn': {
        color: '#fff',
        backgroundColor: '#3b5998',
        borderColor: 'rgba(0,0,0,0.2)'
    }
};

var Space = exports.Space = {
    display: 'inline-block',
    width: '20px'
};

var A = exports.A = {
    color: '#4183c4',
    cursor: 'pointer'
};

var Pre = exports.Pre = {
    overflow: 'auto',
    fontFamily: 'Menlo,\n                Monaco,\n                Consolas,\n                "Courier New",\n                monospace',
    display: 'block',
    padding: '9.5px',
    margin: '0 0 10px',
    fontSize: '13px',
    lineHeight: '1.42857143',
    color: '#333',
    wordBreak: 'break-all',
    wordWrap: 'break-word',
    backgroundColor: '#f5f5f5',
    border: '1px solid #ccc',
    borderRadius: '4px'
};

var Col1 = exports.Col1 = {
    display: 'inline-block',
    width: '8.33333333%'
};

var Col2 = exports.Col2 = {
    display: 'inline-block',
    width: '16.66666667%'
};

var Col3 = exports.Col3 = {
    display: 'inline-block',
    width: '25%'
};

var Col4 = exports.Col4 = {
    display: 'inline-block',
    width: '33.33333333%'
};

var Col5 = exports.Col5 = {
    display: 'inline-block',
    width: '41.66666667%'
};

var Col6 = exports.Col6 = {
    display: 'inline-block',
    width: '50%'
};

var Col7 = exports.Col7 = {
    display: 'inline-block',
    width: '58.33333333%'
};

var Col8 = exports.Col8 = {
    display: 'inline-block',
    width: '66.66666667%'
};

var Col9 = exports.Col9 = {
    display: 'inline-block',
    width: '75%'
};

var Col10 = exports.Col10 = {
    display: 'inline-block',
    width: '83.33333333%'
};

var Col11 = exports.Col11 = {
    display: 'inline-block',
    width: '91.66666667%'
};

var Col12 = exports.Col12 = {
    display: 'inline-block',
    width: '100%'
};

var Hidden = exports.Hidden = {
    display: 'none'
};

var Bootstrap = {
    container: Container,

    navBar: NavBar,
    nav: Nav,
    navRight: NavRight,
    navItem: NavItem,
    navButton: NavButton,

    formContainer: FormContainer,
    formSection: FormSection,
    errorSection: ErrorSection,
    sectionHeader: SectionHeader,
    sectionBody: SectionBody,
    sectionFooter: SectionFooter,

    formRow: FormRow,
    actionRow: ActionRow,

    space: Space,

    signInButton: SignInButton,

    input: Input,
    button: Button,
    a: A,
    pre: Pre,

    col1: Col1,
    col2: Col2,
    col3: Col3,
    col4: Col4,
    col5: Col5,
    col6: Col6,
    col7: Col7,
    col8: Col8,
    col9: Col9,
    col10: Col10,
    col11: Col11,
    col12: Col12,

    hidden: Hidden
};

exports.default = Bootstrap;