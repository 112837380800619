/* eslint-disable no-console */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
// CUSTOM
import ProspectProfileCard from './components/ProspectProfileCard';
import {decodeQueryString} from '../../util/queryString';
import ProspectProfileSummary from './components/ProspectProfileSummary';
import ExportModal from '../../components/ExportModal';
import * as fileDownload from 'js-file-download';
import callApi from '../../util/callApi';
import {
    Button,
    Grid,
    Dimmer,
    Icon,
    Loader,
    Header,
    Segment,
    Rating,
    Placeholder,
    Menu
} from 'semantic-ui-react';
import {
    setStars,
    toggleFlag
} from '../../actions';
import {connect} from 'react-redux';
import ProspectProfileMap from './components/ProspectProfileMap';
import {Helmet} from 'react-helmet/es/Helmet';
import {toTitleCase} from '../../util/lib';

class ProspectProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            flagged: false,
            gridFilterName: 'All'
        };
        // touching this qs thing is dangerous (adi: lol)
        const qs = decodeQueryString();
        if (!qs || !qs.id) {
            this.props.history.push('/');
        }

        this.state.profileId = qs['id'];
    }

    componentWillUnmount() {
        document.body.style.backgroundColor = '#fff';
    }

    async componentDidMount() {
        document.body.style.backgroundColor = 'rgb(247, 247, 247)';
        this.setState({isLoading: true});
        const components = await callApi('db/getColumns', 'select');
        console.log('components retrieved in prospectprofile: ', components);
        this.setState({components: components, ogComponents: components});

        const {data: prospect} = await callApi('db/getProspectByID', 'select', {
            target: {
                'key': 'org_id',
                'operator': '=',
                'value': this.state.profileId
            }
        });

        const org_id = Array.isArray(prospect) ? prospect[0].org_id : prospect.org_id;
        let stars = 0;
        if (this.props.prospectInteraction && this.props.prospectInteraction.starred && this.props.prospectInteraction.starred.filter(o => org_id === o.org_id).length > 0) {
            // eslint-disable-next-line prefer-destructuring
            stars = this.props.prospectInteraction.starred.filter(o => org_id === o.org_id)[0].stars;
        }

        // console.log('AYYYYO got a prospect: ', prospect)
        this.setState({
            prospect: Array.isArray(prospect) ? prospect[0] : prospect,
            flagged: Array.isArray(prospect) ? this.props.prospectInteraction.flagged.includes(prospect[0].org_id) : this.props.prospectInteraction.flagged.includes(prospect.org_id),
            isLoading: false,
            rating: stars,
        }, () => console.log('Set prospect in state, prospect: ', this.state.prospect));

    }

    handleSingleProspectExport = async destination => {
        console.log('This is the handleSingleXportProsjksdbjksg', destination);
        const {data, statusCode} = await this.handleExportProspectData(null, null, false, destination);
        if (destination === 'csv') {
            fileDownload(data, `${this.state.prospect.org_id}-prospect.csv`);
        } else {
            console.log(destination);
        }

        return statusCode;

    };

    async getProspectListData(filters, pageNumber, pageSize, sortBy, exportLocation = null) {
        try {
            this.setState({isLoading: true});

            const {data, total, statusCode} = await callApi(exportLocation ? `db/${exportLocation}` : 'db/getProspectListData', exportLocation ? 'export' : 'select', {
                'table': 'organization_prospect',
                'target': filters,
                'pageNumber': pageNumber - 1,
                'pageSize': pageSize,
                'sortBy': sortBy
            }, 'POST');

            // console.log(data, total)
            // NOTE: likely want to change how this works eventually
            this.setState({
                // possibleHeaders: this.getPossibleHeaders(data).filter(key => key !== '_id'),
                isLoading: false
            });

            // console.log(result)
            return {
                'data': data,
                'pageCount': Math.ceil(total / pageSize),
                'totalRecords': total,
                'numResults': data.length,
                'statusCode': statusCode
            };
        } catch (err) {
            console.error('error getting data for prospect list', err);
            this.setState({
                isLoading: false,
                error: `Error getting data for prospect list, ${JSON.stringify(err)}`
            });
        }
    }
    // eslint-disable-next-line no-unused-vars
    async handleExportProspectData(pageNumber = null, pageLimit = null, selected = false, destination) {
        try {
            const {prospect} = this.state;
            // Calls the api with the current prospect list
            // Attempts to get the data
            const filterSet = [ {key: 'org_id', value: prospect.org_id, operator: '='} ];

            // filters, pageNumber, pageSize, sortBy, exportLocation = null
            const {data, statusCode} = await this.getProspectListData(
                filterSet,
                null,
                null,
                false,
                destination
            );
            return {data, statusCode};
        } catch (err) {
            console.error('error setting prospect data', err);
            this.setState({'loadError': err, 'prospectData': null});

            return [];
        }
    }

    componentDidUpdate(prevProps /* , prevState, snapshot*/) {
        if (this.state.prospect) {
            if (prevProps.prospectInteraction.flagged.length !== this.props.prospectInteraction.flagged.length) {
                this.setState({flagged: this.props.prospectInteraction.flagged.includes(this.state.prospect.org_id)});
            }
        }
    }

    buildProfileComponents = () => {
        console.log('Building comps');
        const compsByRow = [ [] ];
        let rowWidth = 0;
        let currentRow = 0;
        if (this.state.components) {
            const activeComps = this.state.components.filter(comp => this.state.prospect[comp.id]).sort((compA, compB) => (compA.displayOrder < compB.displayOrder ? -1 : 1));
            let i = 0;
            for (const comp of activeComps) {
                console.log(comp.size);
                i++;
                if (comp.size + rowWidth > 16) {
                    if (rowWidth < 16 && compsByRow[currentRow].length !== 0) {
                        const remainder = 16 - rowWidth;
                        // console.log(compsByRow)
                        compsByRow[currentRow][compsByRow[currentRow].length - 1].size += remainder;
                    }

                    rowWidth = comp.size;
                    if (i === activeComps.length) {
                        const remainder = 16 - rowWidth;
                        comp.size += remainder;
                    }

                    currentRow++;
                    compsByRow[currentRow] = [ comp ];
                    // console.log('New', comp.displayName, rowWidth, currentRow, comp.size)
                } else {
                    rowWidth += comp.size;
                    if (i === activeComps.length) {
                        const remainder = 16 - rowWidth;
                        comp.size += remainder;
                    }

                    compsByRow[currentRow].push({...comp});
                    // console.log(comp.displayName, rowWidth, currentRow, comp.size)

                }
                // console.log(i, rowWidth, compsByRow)
            }
        }

        return compsByRow.map((row, i) => (
            <Grid.Row className={'profile'} key={i}>
                {/* {i === 0 && this.state.prospect ?*/}
                {/*    <Grid.Column width={4}>*/}
                {/*        <ProspectProfileSummary prospect={this.state.prospect}/>*/}
                {/*    </Grid.Column>*/}
                {/*    : null*/}
                {/* }*/}
                {row.map((comp, j) => (
                    <Grid.Column width={comp.size || null} key={`${i}-${j}`}>
                        {comp.type === 'list' ?
                            <ProspectProfileCard name={comp.displayName} columns={comp.columns} chart={comp.chart}
                                showCount={comp.showCount}
                                data={this.state.prospect[comp.id]} sort={comp.sort} key={comp.id}/> :
                            null}
                    </Grid.Column>
                ))}
            </Grid.Row>
        ));
    }

    handleRating = (e, {rating}) => {
        this.props.setStars(this.state.prospect.org_id, rating);
        this.setState({rating});
    }

    handleGridFilter = (e, {name}) => {
        if (name === 'All') {
            this.setState({components: this.state.ogComponents, gridFilterName: name});
        } else {

            this.setState({components: this.state.ogComponents.filter(c => c.category === name), gridFilterName: name});
        }
    }

    render() {
        return (
            <Fragment>
                {
                    this.state.prospect ?

                        <Helmet>
                            <title>{toTitleCase(this.state.prospect.name)} | Mozaic</title>
                        </Helmet> :

                        null
                }
                <Helmet>
                    <title>{}</title>
                </Helmet>
                <Dimmer active={this.state.isLoading}>
                    <Loader>Loading</Loader>
                </Dimmer>
                <Grid centered doubling style={{maxWidth: '1780px', margin: '0 auto', }}>
                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column width={3}>
                            <Button
                                primary
                                onClick={() => this.props.history.push('/prospect/')}>
                                <Icon name='chevron left'/>
                                Back
                            </Button>
                        </Grid.Column>
                        <Grid.Column computer={5} mobile={13} textAlign='right'>
                            {
                                this.state.prospect ?
                                    <Header as='h2'>{this.state.prospect.name}</Header> :
                                    <Placeholder>
                                        <Placeholder.Header>
                                            <Placeholder.Line/>
                                            <Placeholder.Line/>
                                        </Placeholder.Header>
                                    </Placeholder>
                            }
                        </Grid.Column>
                        <Grid.Column width={4} textAlign='left'>
                            <Rating rating={this.state.rating || 0} maxRating={5} size='large'
                                onRate={(e, v) => this.handleRating(e, v)}/>
                        </Grid.Column>
                        <Grid.Column width={2} textAlign='right'>
                            <ExportModal functionality={this.handleSingleProspectExport}>
                                <Button style={{height: 36, width: 120}}>
                                    <Icon name='download'/> Export
                                </Button>
                            </ExportModal>
                            {/* <Dropdown
                                disabled
                                text='Export'
                                icon='download'
                                floating
                                labeled
                                button
                                className='icon'
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Header>CRM</Dropdown.Header>
                                    <Dropdown.Item text="Salesforce"/>
                                    <Dropdown.Header>File</Dropdown.Header>
                                    <Dropdown.Item text="CSV (Excel)"/>
                                    <Dropdown.Item text="JSON"/>
                                </Dropdown.Menu>
                            </Dropdown> */}
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <Button onClick={() => this.props.toggleFlag(this.state.prospect.org_id)}
                                color={this.state.flagged ? 'green' : null}>
                                <Icon name='flag'/>

                                {this.state.flagged ? 'Unflag' : 'Flag'}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            {this.state.prospect ?
                                <Segment attached='top' style={{padding: 0}}>
                                    <ProspectProfileMap
                                        geo={this.state.prospect.geocoding ? this.state.prospect.geocoding : null}
                                    />
                                </Segment> :
                                null
                            }
                            <Segment attached='bottom'>
                                <ProspectProfileSummary prospect={this.state.prospect}/>
                            </Segment>
                            <Menu secondary>
                                <Menu.Item><Menu.Header as='h5'>Data Source</Menu.Header></Menu.Item>
                                <Menu.Item name={'All'} active={this.state.gridFilterName === 'All'} link
                                    onClick={(e, v) => this.handleGridFilter(e, v)}/>
                                {this.state.ogComponents ?
                                    [ ...new Set(this.state.ogComponents.map(c => c.category)) ].map((cat, i) => (
                                        <Menu.Item name={cat} active={this.state.gridFilterName === cat} link
                                            onClick={(e, v) => this.handleGridFilter(e, v)} key={i}/>

                                    )) :
                                    null}
                            </Menu>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Grid centered stretched stackable style={{maxWidth: '1780px', margin: '0 auto'}}>
                    {
                        this.state.components && this.state.prospect ?
                            this.buildProfileComponents() :
                            <Segment placeholder></Segment>
                    }
                </Grid>
            </Fragment>
        );
    }
}

ProspectProfile.propTypes = {
    history: PropTypes.array,
    prospectInteraction: PropTypes.object,
    setStars: PropTypes.func,
    toggleFlag: PropTypes.func
};

function mapStateToProps({prospectInteraction}) { // eslint-disable-line func-style
    return {prospectInteraction};
}

const mapDispatchToProps = {
    setStars, toggleFlag
};

export default connect(mapStateToProps, mapDispatchToProps)(ProspectProfile);