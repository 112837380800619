import React from 'react';
import {Grid, Segment} from 'semantic-ui-react';
import PropTypes from 'prop-types';
class AuthWrapper extends React.Component {

    render() {

        return (
            <Grid centered colums={1} style={{paddingTop: '5em'}}>
                <Grid.Column computer={5} tablet={8} mobile={12}>
                    <Segment stacked>
                        {this.props.children}
                    </Segment>
                </Grid.Column>
            </Grid>
        );
    }
}

AuthWrapper.propTypes = {
    children: PropTypes.node
};

export default AuthWrapper;