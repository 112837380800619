/* eslint-disable no-console */
import React, {Component} from 'react';
import {
    Button,
    Segment,
    Sidebar,

} from 'semantic-ui-react';
import SidebarContent from './components/SidebarContent';
import ProspectTable from './components/ProspectTable';
import ProspectMap from './components/ProspectMap';
import {connect} from 'react-redux';
import callApi from '../../util/callApi';
import {Helmet} from 'react-helmet/es/Helmet';
import PropTypes from 'prop-types';

class ProspectList extends Component {

    constructor(props) {
        super(props);
        this.handleExportProspectData = this.handleExportProspectData.bind(this);
        this.retrieveMapData = this.retrieveMapData.bind(this);
        this.state = {
            animation: 'uncover',
            direction: 'left',
            visible: true,
            isLoading: true,
            activeItem: null,
            selectedForExport: new Set()
        };
        // If zoom >= zoomThreshold, fetch and show org details in map popups
        this.zoomThreshold = 6.5;   // Must be between 0 to 24 (inclusive)
        this.handleResize = this.handleResize.bind(this);

    }

    componentDidMount() {
        if (this.props.userStateLoaded) {
            this.retrieveData();
        }

        this.setState({
            fullWidth: document.getElementById('prospect-list-wrapper').offsetWidth,
            pushableWidth: document.getElementById('prospect-list-wrapper').offsetWidth - document.getElementById('prospect-list-sidebar').offsetWidth
        });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener('resize', null);
    }

    handleResize(/* WindowSize, event */) {
        this.setState({
            fullWidth: document.getElementById('prospect-list-wrapper').offsetWidth,
            pushableWidth: document.getElementById('prospect-list-wrapper').offsetWidth - document.getElementById('prospect-list-sidebar').offsetWidth
        });
    }


    componentDidUpdate(prevProps /* , prevState, snapshot */) {
        if (JSON.stringify(prevProps.prospectList.filters) !== JSON.stringify(this.props.prospectList.filters)) {
            this.retrieveData();
            this.retrieveMapData(); // If filters are changed, update map data too
        } else if (prevProps.prospectList.selectedColumns.length !== this.props.prospectList.selectedColumns.length) {
            this.retrieveData();
        } else if (prevProps.prospectList.currentPage !== this.props.prospectList.currentPage) {
            this.retrieveData();
        } else if (prevProps.prospectList.pageSize !== this.props.prospectList.pageSize) {
            this.retrieveData();
        } else if (prevProps.prospectList.sortDirection !== this.props.prospectList.sortDirection || prevProps.prospectList.sortColumn !== this.props.prospectList.sortColumn) {
            this.retrieveData();
        } else if (prevProps.userStateLoaded !== this.props.userStateLoaded) {
            this.retrieveData();
        } else if (this.props.prospectList.zoom >= this.zoomThreshold && prevProps.prospectList.zoom < this.zoomThreshold || this.props.prospectList.zoom < this.zoomThreshold && prevProps.prospectList.zoom >= this.zoomThreshold) {
            this.retrieveMapData(); // If the user crosses the zoom threshold level (either zooming in or out), update map data
        }
    }

    async getProspectListData(filters, pageNumber, pageSize, sortBy, exportLocation = null) {
        try {
            this.setState({isLoading: true});

            const {data, total, statusCode} = await callApi(exportLocation ? `db/${exportLocation.destination}` : 'db/getProspectListData', exportLocation ? 'export' : 'select', {
                'table': 'organization_prospect',
                'target': filters,
                'pageNumber': pageNumber - 1,
                'pageSize': pageSize,
                'sortBy': sortBy
            }, 'POST');

            // console.log(data, total)
            // NOTE: likely want to change how this works eventually
            this.setState({
                // possibleHeaders: this.getPossibleHeaders(data).filter(key => key !== '_id'),
                isLoading: false
            });

            // console.log(result)
            return {
                'data': data,
                'pageCount': Math.ceil(total / pageSize),
                'totalRecords': total,
                'numResults': data.length,
                'statusCode': statusCode
            };
        } catch (err) {
            console.error('error getting data for prospect list', err);
            this.setState({
                isLoading: false,
                error: `Error getting data for prospect list, ${JSON.stringify(err)}`
            });
        }
    }

    async getProspectMapData(zoom, filters) {
        // Only fetch coordinates when zoomed out beyond threshold; fetch org id, name, and address info as well when zoomed in
        const projectionObj = zoom < this.zoomThreshold ?
            {
                geocoding: 1
            } :
            {
                geocoding: 1,
                org_id: 1,
                name: 1,
                primary_state: 1,
                primary_city: 1,
                address_line1: 1,
                address_line2: 1
            };

        try {
            const {data, total, statusCode} = await callApi(
                'db/getProspectMapData',
                'select',
                {
                    'table': 'organization_prospect',
                    'target': [...filters, {key: 'geocoding.successful', operator: '=', value: true} ],
                    'pageSize': 100000,
                    'projection': projectionObj
                },
                'POST');

            this.setState({isLoading: false});

            return {
                'data': data,
                'totalRecords': total,
                'numResults': data.length,
                'statusCode': statusCode
            };
        } catch (err) {
            console.error('error getting data for prospect map', err);
            this.setState({
                isLoading: false,
                error: `Error getting data for prospect map, ${JSON.stringify(err)}`
            });
        }
    }

    async handleExportProspectData(pageNumber = null, pageLimit = null, selected = false, destination) {
        try {
            const {prospectList} = this.props;
            // Calls the api with the current prospect list
            // Attempts to get the data
            let filterSet = prospectList.filters.length ? prospectList.filters : [];
            if (selected) {
                filterSet = [ {key: 'org_id', operator: 'in', value: Array.from(this.state.selectedForExport)} ];
            }

            const {data, statusCode} = await this.getProspectListData(
                filterSet,
                pageNumber || this.state.pageNumber,
                pageLimit || this.state.pageSize,
                {
                    key: prospectList.sortColumn,
                    descending: prospectList.sortDirection === 'descending'
                },
                {destination}
            );

            return {data, statusCode};

        } catch (err) {
            console.error('error setting prospect data', err);
            this.setState({'loadError': err, 'prospectData': null});

            return [];
        }
    }

    // parent
    async retrieveData() {
        console.log('Retrieving data');
        this.setState({isLoading: true});
        try {
            // Calls the api with the current prospect list
            // Attempts to get the data
            const resultList = await this.getProspectListData((this.props.prospectList.filters || []).length > 1 || Object.keys((this.props.prospectList.filters || [])[0] || {}).length ? this.props.prospectList.filters : [], this.props.prospectList.currentPage, this.props.prospectList.pageSize || 10, {
                key: this.props.prospectList.sortColumn,
                descending: this.props.prospectList.sortDirection === 'descending'
            });

            this.setState({
                'prospectData': resultList.data || [],
                'pageCount': resultList.pageCount,
                'totalRecords': resultList.totalRecords,
                'totalResults': resultList.numResults,
                'loadError': resultList.data.length ? null : 'No results for this query.  Please consider revising your filters.',
                'numPages': resultList.pageCount
            });

            this.setState({isLoading: false});

        } catch (err) {
            console.error('error setting prospect list', err);
            this.setState({'loadError': err, 'prospectData': null});
        }
    }

    async retrieveMapData() {
        this.setState({isLoading: true});
        try {
            const resultList = await this.getProspectMapData(this.props.prospectList.zoom, (this.props.prospectList.filters || []).length > 1 || Object.keys((this.props.prospectList.filters || [])[0] || {}).length ? this.props.prospectList.filters : []);

            this.setState({
                'mapFeatures': {
                    type: 'FeatureCollection',
                    features: resultList.data || []
                },
                'totalRecords': resultList.totalRecords,
                'totalResults': resultList.numResults,
                'loadError': resultList.data.length ? null : 'No results for this query.  Please consider revising your filters.',
            });

            this.setState({isLoading: false});

        } catch (err) {
            console.error('error setting prospect map', err);
            this.setState({'loadError': err, 'mapFeatures': null});
        }
    }

    handleClearSelectedForExport = () => {
        this.setState({selectedForExport: new Set()});
    }

    handleRowExportToggle = (data, org_id) => {
        // data.checked = whether the box was ticked or unticked, use that to determine addition or removal from final export
        const {checked} = data;
        if (checked) {
            this.setState(({selectedForExport}) => ({
                selectedForExport: new Set(selectedForExport).add(org_id)
            }), () => console.log('Added item to set, new size: ', this.state.selectedForExport.size));
        } else {
            this.setState(({selectedForExport}) => {
                const newSet = new Set(selectedForExport);
                newSet.delete(org_id);

                return {selectedForExport: newSet};
            }, () => console.log('Removed items from set, new size: ', this.state.selectedForExport.size));
        }
    }

    // Sidebar animation and direction handlers.
    handleSidebarAnimationChange = animation => () => this.setState(prevState => ({animation: animation, visible: !prevState.visible}))

    handleSidebarDirectionChange = direction => () => this.setState({direction: direction, visible: false})

    render() {
        const {animation, direction, visible, selectedForExport, prospectData, isLoading, totalRecords, totalResults, pageCount, mapFeatures} = this.state;
        const {mapView} = this.props.prospectList; // boolean for map view or list view
        const vertical = direction === 'bottom' || direction === 'top';
        // This changes the icon depending on the state of visibility for the sidebar
        const iconDirection = visible ? 'angle left' : 'angle double right';
        // This changes the width of the table depending on vsibility of the sidebar
        const columnWidth = this.state.pushableWidth ? visible ? this.state.pushableWidth : this.state.fullWidth : null;

        return (
            <>
                <Helmet>
                    <title>Prospect List | Mozaic</title>
                </Helmet>
                <Sidebar.Pushable as={Segment}
                    style={{border: 0, margin: 0, padding: '0 !important', boxShadow: 'none'}}
                    id={'prospect-list-wrapper'}>
                    <Sidebar
                        id={'prospect-list-sidebar'}
                        animation={animation}
                        direction={direction}
                        // icon='labeled'
                        vertical
                        visible={visible}
                    >
                        <SidebarContent
                            style={{position: 'sticky'}}

                            handleClearSelectedForExport={this.handleClearSelectedForExport}
                            isLoading={isLoading}
                            selectedForExport={selectedForExport}
                            handleExportProspectData={this.handleExportProspectData}
                            totalRecords={totalRecords}
                        />
                    </Sidebar>
                    <Sidebar.Pusher>
                        <Segment basic style={columnWidth ? {width: columnWidth} : null}>
                            <Button

                                floated='left'
                                icon={iconDirection}
                                color='yellow'
                                // attached='right'
                                disabled={vertical}
                                onClick={this.handleSidebarAnimationChange('uncover')}
                                style={{position: 'absolute', zIndex: 10, width: '3.8em'}}
                            ></Button>
                            {/* In order for the sidebar to be revealed, the content below is what must be "pushed" to the side. In this case, it's the listed content. */}
                            {mapView ?
                                < ProspectMap
                                    isLoading={isLoading}
                                    sidebarVisible={visible}
                                    mapFeatures={mapFeatures}
                                    retrieveMapData={this.retrieveMapData}
                                    zoomThreshold={this.zoomThreshold}
                                /> :
                                <ProspectTable
                                    selectedForExport={selectedForExport}
                                    prospectData={prospectData}
                                    isLoading={isLoading}
                                    totalResults={totalResults}
                                    totalRecords={totalRecords}
                                    pageCount={pageCount}
                                    handleRowExportToggle={this.handleRowExportToggle}
                                    history={this.props.history}
                                />
                            }
                        </Segment>

                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </>
        );
    }
}

ProspectList.propTypes = {
    history: PropTypes.array,
    prospectList: PropTypes.object,
    userStateLoaded: PropTypes.bool,
};

// eslint-disable-next-line func-style
function mapStateToProps({prospectList, prospectInteraction}) {
    return {prospectList, prospectInteraction};
}

export default connect(mapStateToProps)(ProspectList);
