import React, { Component, Fragment } from 'react';
import { Image, Menu, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import mozaicLogo from '../../images/mozaic-logo-white.svg';
import { withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';

class Header extends Component {

    async handleSignOut() {
        await Auth.signOut();
        window.location.href = '/';
    }

    render() {
        return (
            <Menu inverted style={{ borderRadius: 0, backgroundColor: '#272d2d' }} icon='labeled'>
                <Menu.Item header>
                    <Image src={mozaicLogo} style={{ marginTop: '5px', height: '35px' }} />
                </Menu.Item>
                <Menu.Menu position='right'>
                    {this.props && this.props.auth ?
                        <Fragment>
                            {/* <Menu.Item*/}
                            {/*    name='Dashboard'*/}
                            {/*    active={this.props && this.props.location.pathname === '/'}*/}
                            {/*    link*/}
                            {/*    onClick={() => this.props.history.push('/')}*/}
                            {/* >*/}
                            {/*    <Icon name='line graph' />*/}
                            {/*    Dashboard*/}
                            {/* </Menu.Item>*/}
                            <Menu.Item
                                name='Prospects'
                                active={this.props && this.props.location.pathname.includes('/prospect')}
                                link
                                onClick={() => this.props.history.push('/prospect/')}
                            >
                                <Icon name='th' />
                                Prospects
                            </Menu.Item>
                            <Menu.Item
                                name='Tools'
                                active={this.props && this.props.location.pathname.includes('/tools')}
                                link
                                onClick={() => this.props.history.push('/tools/')}
                            >
                                <Icon name='sliders horizontal' />
                                Tools
                            </Menu.Item>
                            <Menu.Item
                                name='Settings'
                                active={this.props && this.props.location.pathname.includes('/account')}
                                link
                                onClick={() => this.props.history.push('/account/details')}
                            >
                                <Icon name='settings' />
                                Settings
                            </Menu.Item>
                            <Menu.Item
                                onClick={() => this.handleSignOut()}
                            >
                                <Icon name='sign-out' />
                                Sign Out
                            </Menu.Item>
                        </Fragment> :
                        <Menu.Item
                            active
                            onClick={() => this.props.history.push('/')}
                        >
                            <Icon name='sign-in' />
                            Sign In
                        </Menu.Item>}
                </Menu.Menu>
            </Menu>
        );
    }
}

Header.propTypes = {
    auth: PropTypes.bool,
    history: PropTypes.array,
    location: PropTypes.object
};
// eslint-disable-next-line func-style
function mapStateToProps({ auth }) {
    return { auth };
}

export default withRouter(connect(mapStateToProps)(Header));