export const FETCH_USER = 'fetch_user';

export const ADD_FILTER = 'add_filter';

export const REMOVE_FILTER = 'remove_filter';

export const EDIT_FILTER = 'edit_filter';

export const SET_COLUMNS = 'set_columns';

export const SET_PAGE_SIZE = 'set_page_sze';

export const SET_SORT = 'set_sort';

export const SET_PAGE_NUMBER = 'set_page_number';

export const SET_PROSPECT_LIST_STATE = 'set_prospect_list_state';

export const TOGGLE_MAP_VIEW = 'toggle_map_view';

export const SET_ZOOM = 'set_zoom';

export const SET_COORDINATES = 'set_coordinates';

export const SET_STARS = 'set_stars';

export const TOGGLE_FLAG = 'toggle_flag';

export const SET_PROSPECT_INTERACTION_STATE = 'set_prospect_interaction_state';