import React from 'react';
import {Placeholder, Table} from 'semantic-ui-react';
import PropTypes from 'prop-types';

class LoadingTable extends React.Component {
    render() {
        const {rows, columns} = this.props;
        const lengths = ['full', 'very long', 'long', 'medium', 'short', 'very short'];
        return (
            <Table definition sortable celled striped selectable>
                <Table.Header>
                    {Array.apply(null, Array(columns))
                        .map((x, i) => <Table.HeaderCell key={i}><Placeholder key={i}>
                            <Placeholder.Header key={i}>
                                <Placeholder.Line length={lengths[Math.floor(Math.random() * 6)]} key={i}/>
                            </Placeholder.Header>
                        </Placeholder></Table.HeaderCell>)}
                </Table.Header>
                <Table.Body>
                    {Array.apply(null, Array(rows))
                        .map((x, i) => <Table.Row key={i}>
                            {Array.apply(null, Array(columns))
                                .map((x, j) => <Table.Cell key={j}>
                                    <Placeholder key={j}>
                                        <Placeholder.Line length={lengths[Math.floor(Math.random() * 6)]} key={i}/>
                                    </Placeholder>
                                </Table.Cell>)}
                        </Table.Row>)}
                </Table.Body>
            </Table>
        );
    }
}

export default LoadingTable;

LoadingTable.propTypes = {
    columns: PropTypes.number,
    rows: PropTypes.number
};