/* eslint-disable */
import React from 'react';

import {VerifyContact} from 'aws-amplify-react';

import AuthWrapper from "./components/AuthWrapper";

class CustomVerifyContact extends VerifyContact
{

    constructor(props)
    {

        super(props);

        this.superShowComponent = this.showComponent;

        this.showComponent = (theme) =>
        {

            if (window.document)
            {

                window.document.title = 'Verify Contact | ' + (process.env['REACT_APP_SITE_TITLE'] || 'Wheelhouse Boilerplate App');
            }

            return (
                <AuthWrapper>

                    {this.superShowComponent(theme)}

                </AuthWrapper>
            );
        };

        this.superComponentDidMount = this.componentDidMount;

        // this.componentDidMount = () =>
        // {
        //
        //     if (typeof this.superComponentDidMount === 'function')
        //     {
        //
        //         this.superComponentDidMount();
        //     }
        // }
    }
}

export default CustomVerifyContact;